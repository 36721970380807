import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import consts from "const";
import CurrencyFormat from 'react-currency-format';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});


class EmployeesList extends React.Component  {
  state = {
      list:[]
  };
  fetchData() {
    axios.get(consts.API_BASE_URL + "/api/employees/").then(
        ({data}) => {
            this.setState({list: data})
        }).catch(function (error) {
        console.log(error);
    });
  }
  componentDidMount() {
    this.fetchData();
  }
  render() {
  return (
    <Paper className={this.props.classes.root}>
      <Table className={this.props.classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Total sales</TableCell>
            <TableCell>Count sales</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.list.map(n => {
            return (
              <TableRow key={n.id}>
                <TableCell component="th" scope="row">
                  {n.name}
                </TableCell>
                <TableCell>
                  <CurrencyFormat
                      value={n.total_sales || 0} decimalScale={2}
                      displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                  </TableCell>
                <TableCell>{n.count_sales}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}
}

EmployeesList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmployeesList);
