import { connect } from "react-redux";
import axios from 'axios';
import consts from '../../const';
import { changeDate, changeLive } from "../../redux/actions/dates";
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Button } from "components";
import Settings from "@material-ui/icons/Settings";
import Timer from "@material-ui/icons/Timer";
import momentPropTypes from "react-moment-proptypes";
import IconButton from "@material-ui/core/IconButton";
import { withStyles, Menu, MenuItem } from "@material-ui/core";
import CalendarStyle from "assets/jss/material-dashboard-react/calendarStyle";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from "material-ui-pickers";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MomentUtils from "@date-io/moment";
import { FilterList, Done } from "@material-ui/icons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";

var capitalize = require("underscore.string/capitalize");

const perDays = [
  "all days",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
  "weekdays",
  "weekends",
];

const optionsLive = ["1", "5", "15", "30", "60"];

let optionsFilter = ["Employee", "Device","Holidays","Category","Product"];

const groupBy = ["day", "week", "month", "year"];

const ITEM_HEIGHT = 48;

const CURRENT_VERSION=2;


class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startFocused: false,
      endFocused: false,
      anchorPerDay: null,
      anchorTime: null,
      anchorDevice: null,
      anchorEmployee: null,
      anchorPerGroup: null,
      anchorFilter: null,
      anchorEl: null,
      anchorCategory: null,
      anchorProduct: null,
      openCustomDate: false,
      filters: optionsFilter,
      dataTab:null,
      intervalId:false,
      showItemsSold:false,
      categoriesHidden:[],
      lastRefresh: moment().format("MMMM Do YYYY, h:mm a"),
    };
  }

  getConfigDashboard() {
    if (localStorage.getItem('dataTab') != 'undefined'){
      let dataTab = localStorage.getItem('dataTab');
    }
    let version = localStorage.getItem('dataVersion', 0);
    let headers = {
        Authorization: `JWT ${localStorage.getItem('token')}`
    };

    // if(version < CURRENT_VERSION){
    //     dataTab=null;
    // }

        axios.get(`${consts.API_BASE_URL}/api/users/me`, {headers})
                .then(res => { 
                    if (res.data.data.filters!==undefined){
                      this.setState({
                        dataTab: res.data.data.data,
                        filters: res.data.data.filters,
                        showItemsSold: res.data.data.showItemsSold,
                        categoriesHidden:res.data.data.categoriesHidden
                    })
                    }else{
                      this.setState({
                        dataTab: res.data.data.data,
                        filters: [],
                        showItemsSold: res.data.data.showItemsSold,
                        categoriesHidden:res.data.data.categoriesHidden
                    })
                    }
                });

}

postConfigDashboard(data) {
  // localStorage.setItem('dataTab', JSON.stringify(this.state.dataTab));
  // localStorage.setItem('filters', JSON.stringify(this.state.filters));
  // let showItemsSold = JSON.parse(localStorage.getItem('showItemsSold'))

  let headers = {
      Authorization: `JWT ${localStorage.getItem('token')}`
  };
  let d = new Date();
  axios.put(`${consts.API_BASE_URL}/api/users/me`, {data:{data,filters:this.state.filters, version:CURRENT_VERSION, date:d.toUTCString(),showItemsSold: this.state.showItemsSold, categoriesHidden:this.state.categoriesHidden }}, {headers})
              .then(res => {


              });
}



  componentDidMount() {
    this.getConfigDashboard();
    this.setState((state, props) => ({
      startDate: props.startDate,
      endDate: props.endDate,
    }));
    
  }

  componentDidUpdate(prevProps,prevState) {
    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate
    ) {
      this.setState({
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });
    }

    if(
      !this.props.category
    ){
      optionsFilter = ["Employee", "Device","Holidays"];
    }else{
      optionsFilter = ["Employee", "Device","Holidays","Category","Product"];
    }


    if(prevState.filters !== this.state.filters ){
      this.postConfigDashboard(this.state.dataTab);
      localStorage.setItem('dataTab', JSON.stringify(this.state.dataTab));
      localStorage.setItem('dataVersion', CURRENT_VERSION);
      localStorage.setItem('filters', JSON.stringify(this.state.filters));
  }






  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  setDateCustom = (startDate, endDate) => {
    this.props.changeDate({
      startDate,
      endDate,
      timeSelect: "Custom",
    });
    this.setState({ anchorTime: null, openCustomDate: false });
  };

  setDate = (timeSelect) => {
    let startDate, endDate;
    if (timeSelect === "today") {
      startDate = moment();
      endDate = moment();
    } else if (timeSelect === "Yesterday") {
      startDate = moment().subtract(1, "days");
      endDate = moment().subtract(1, "days");
    } else if (timeSelect === "ThisWeek") {
      startDate = moment().startOf("isoWeek");
      endDate = moment();
    } else if (timeSelect === "LastWeek") {
      startDate = moment().subtract(1, "weeks").startOf("isoWeek");
      endDate = moment().subtract(1, "weeks").endOf("isoWeek");
    } else if (timeSelect === "ThisMonth") {
      startDate = moment().startOf("month");
      endDate = moment();
    } else if (timeSelect === "Last7Days") {
      startDate = moment().subtract(6, "days");
      endDate = moment();
    } else if (timeSelect === "Last30Days") {
      startDate = moment().subtract(1, "month");
      endDate = moment();
    } else if (timeSelect === "LastMonth") {
      startDate = moment().subtract(1, "months").startOf("month");
      endDate = moment().subtract(1, "months").endOf("month");
    } else if (timeSelect === "Last3Months") {
      startDate = moment().subtract(3, "months");
      endDate = moment();
    } else if (timeSelect === "Last6Months") {
      startDate = moment().subtract(6, "months");
      endDate = moment();
    } else if (timeSelect === "PreviousYear") {
      startDate = moment().subtract(1, "year").startOf("year");
      endDate = moment().subtract(1, "year").endOf("year");
    } else if (timeSelect === "YearDate") {
      startDate = moment().startOf("year");
      endDate = moment();
    } else if (timeSelect === "Custom") {
      this.setState({ openCustomDate: true, anchorTime: null });
      return;
    } else if (timeSelect === "All") {
      startDate = false;
      endDate = false;
    }

    if (startDate && endDate) {
      if (true) {
        //!timeSelect
        startDate = startDate.set({
          hour: 0,
          minute: 0,
          second: 59,
        });
        endDate = endDate.set({
          hour: 23,
          minute: 59,
          second: 59,
        });
        //timeSelect = '';
      }
      this.props.changeDate({
        startDate,
        endDate,
        timeSelect,
        anchorTime: null,
      });
      this.setState({ anchorTime: null });
    } else {
      this.props.changeDate({
        allData: true,
        timeSelect,
      });
      this.setState({ anchorTime: null });
    }
  };

  buttonSelected = (timeSelect) => {
    this.setState({
      timeSelect,
    });
  };

  handleCloseCustomDate = () => {
    this.setState({ openCustomDate: false });
  };

  handleClick = (name) => (event) => {
    console.debug();
    this.setState({ [name]: event.currentTarget });
  };
  handleChangeHoliday = (event) => {
    this.props.changeDate({
      holiday: event.target.checked,
    });
  };

  handleClose = () => {
    this.setState({
      anchorTime: null,
      anchorEl: null,
      anchorDevice: null,
      anchorEmployee: null,
      anchorFilter: null,
      anchorCategory: null,
      anchorProduct: null,
      
    });
  };

  
  handleMenuItemClick = (option) => {
    this.props.changeDate({
      optionPerDay: option,
    });
    this.setState({ anchorPerDay: null, optionPerDay: option });
  };

  handleGroupMenuItemClick = ( option) => {
    this.props.changeDate({
      groupBy: option,
    });
    this.setState({ anchorPerGroup: null, groupBy: option });
  };
  handleDeviceMenuItemClick = ( option) => {
    this.props.changeDate({
      device: option,
    });
    this.setState({ anchorDevice: null });
  };
  handleEmployeeMenuItemClick = ( option) => {
    this.props.changeDate({
      employee: option,
    });
    this.setState({ anchorEmployee: null });
  };


  handleCategoryClick = ( tipo, value) => {
    var array = this.props.categories;
    if(tipo == "product"){
      array = this.props.products;
    }
    var instance = this.props.categories.find((obj) => obj.url == value);
    if(instance == null){
      instance = {value: null, label: 'Select All'}
    }
    this.props.onChangeCatProd(tipo, instance)
  };

  handleFilterMenuItemClick = ( option) => {
    let filters = this.state.filters.slice();
    let index = filters.indexOf(option);
    if (index >= 0) {
      filters.splice(index, 1);
    } else {
      filters.push(option);
    }
    this.setState({ filters });
    //copy filters to ls and persist




  };


refreshAndTimer = ()=>{
  this.props.refresh();
  this.setState((state,props)=>(
    {
      lastRefresh:moment().format("MMMM Do YYYY, h:mm a")
    }
    ))
}

handleCloseLive = (liveTime) => {
  this.setState({
    anchorEl: null,
    // liveTime: parseInt(liveTime),
  });

  this.props.changeLive({
    live:true,
    liveTime: parseInt(liveTime),
  });

  if (this.state.intervalId)
    clearInterval(this.state.intervalId);


    var intervalId = setInterval(
      this.refreshAndTimer,
      liveTime * 1000 * 60
    );
    this.setState({intervalId:intervalId})
};


  handleToggleTimer = () => {
    if (!this.props.live) {
      var intervalId = setInterval(
        this.refreshAndTimer,
        this.props.liveTime * 1000 * 60
      );
      this.setState((state,props)=>({
        intervalId: intervalId,
      }));
    } else {
      clearInterval(this.state.intervalId);
    }
    this.props.changeLive({
      live: !this.props.live,
      liveTime: this.props.liveTime
    });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    const open = Boolean(anchorEl);
  
    return (
      <div>
        <TextField
          id="filled-select-date-range"
          select
          label="Date range"
          value={this.props.timeSelect}
          onChange={(event) => this.setDate(event.target.value)}
          classes={{ root: classes.input }}
        >
          <MenuItem
            key={"today"}
            value='today'
          >
            Today
          </MenuItem>

          <MenuItem
            key={"Yesterday"}
            value='Yesterday'
          >
            Yesterday
          </MenuItem>
          {/*<MenuItem  key={"ThisWeek"} selected={"ThisWeek" === this.props.timeSelect} 
                    onClick={event => this.setDate( "ThisWeek")}>
                    This Week
                </MenuItem>
                <MenuItem  key={"LastWeek"} selected={"LastWeek" === this.props.timeSelect} 
                    onClick={event => this.setDate( "LastWeek")}>
                    Last Week
                </MenuItem>*/}

          <MenuItem
            key={"Last7Days"}
            value='Last7Days'
          >
            Last 7 Days
          </MenuItem>
          <MenuItem
            key={"Last30Days"}
            value='Last30Days'
          >
            Last 30 days
          </MenuItem>

          <MenuItem
            key={"ThisMonth"}
            value='ThisMonth'
          >
            Month to Date
          </MenuItem>
          <MenuItem
            key={"LastMonth"}
            value='LastMonth'
          >
            Last Month
          </MenuItem>
          <MenuItem
            key={"Last3Months"}
            value='Last3Months'
          >
            Last 3 Months
          </MenuItem>
          <MenuItem
            key={"Last6Months"}
            value='Last6Months'
          >
            Last 6 Months
          </MenuItem>
          <MenuItem
            key={"PreviousYear"}
            value='PreviousYear'
          >
            Last Year
          </MenuItem>
          <MenuItem
            key={"YearDate"}
            value='YearDate'
          >
            Year to Date
          </MenuItem>
          <MenuItem
            key={"Holidays"}
            value='Holidays'
          >
            Holidays
          </MenuItem>
          <MenuItem
            key={"Custom"}
            value='Custom'
          >
            Custom Date Range
          </MenuItem>
          <MenuItem
            key={"All"}
            value='All'
          >
            All
          </MenuItem>
        </TextField>
        <Dialog
          open={this.state.openCustomDate}
          onClose={this.handleCloseCustomDate}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Select Custom Date</DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container spacing={24}>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Start Date
                  </Typography>
                  <DatePicker
                    margin="normal"
                    label="Date picker"
                    value={this.state.startDate}
                    onChange={(date) => this.setState({ startDate: date })}
                  />
                  <TimePicker
                    margin="normal"
                    label="Time picker"
                    value={this.state.startDate}
                    onChange={(date) => this.setState({ startDate: date })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    End Date
                  </Typography>
                  <DatePicker
                    margin="normal"
                    label="Date picker"
                    value={this.state.endDate}
                    onChange={(date) => this.setState({ endDate: date })}
                  />
                  <TimePicker
                    margin="normal"
                    label="Time picker"
                    value={this.state.endDate}
                    onChange={(date) => this.setState({ endDate: date })}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <DialogContentText>
              To subscribe to this website, please enter your email address
              here. We will send updates occasionally.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseCustomDate} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() =>
                this.setDateCustom(this.state.startDate, this.state.endDate)
              }
              color="primary"
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>

        {this.props.showPerDay === true ? (
          <TextField
          id="simple-menu-perday"
          select
          label="Days included"
          value={this.props.optionPerDay}
          onChange={(event) => this.handleMenuItemClick(event.target.value) }
          classes={{ root: classes.input }}
        >
          {perDays.map((option) => (
            <MenuItem key={option} value={option}
            >
              {capitalize(option)}
            </MenuItem>
          ))}
        </TextField>
        ) : null}
       

        {this.props.showGroupBy === true ? (
           <TextField
           id="group-menu"
           select
           label="Consolidated by"
           value={this.props.groupBy}
           onChange={(event) => this.handleGroupMenuItemClick(event.target.value) }
           classes={{ root: classes.input }}
         >
           {groupBy.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {capitalize(option)}
            </MenuItem>
          ))}
         </TextField>
        ) : null}
        
        {this.state.filters.indexOf("Device") >= 0 && (
          <TextField
          id="simple-menu-device"
          select
          label="Device"
          value={this.props.device}
          onChange={(event) => this.handleDeviceMenuItemClick(event.target.value) }
          classes={{ root: classes.input }}
        >
          <MenuItem
            key={"all"} value='all'
          >
            All
          </MenuItem>

          {this.props.devices.map((option) => (
            <MenuItem key={option.id} value={option.id} >
              {option.product_name}
            </MenuItem>
          ))}
        </TextField>
          
        )}
       
        {this.state.filters.indexOf("Employee") >= 0 && (
          <TextField
          id="simple-menu-employee"
          select
          label="Employee"
          value={this.props.employee.name || "all"}
          onChange={(event) => this.handleEmployeeMenuItemClick(event.target.value) }
          classes={{ root: classes.input }}
        >
          <MenuItem key={"all"} value='all'>All</MenuItem>
          {this.props.employees.map((option) => {
            return (
              <MenuItem
                key={option.id} value={option.id}
              >
                {option.name}
              </MenuItem>
            );
          })}
        </TextField>

        )}

        {this.props.category != null && this.state.filters.indexOf("Category") >= 0 && (
          <TextField
          id="simple-menu-category"
          select
          label="Category"
          value={this.props.category.url == null ? "all": this.props.category.url}
          onChange={(event) =>  this.handleCategoryClick("category", event.target.value)}
          classes={{ root: classes.input }}
        >
          {this.props.categories.map((option) => {
          return (
            <MenuItem
              key={option.url} value={option.url}
            >
              {option.name}
            </MenuItem>
          );
        })}
        </TextField>
        )}


{this.props.product != null && this.state.filters.indexOf("Product") >= 0 && (
            <TextField
            id="simple-menu-product"
            select
            label="Product"
            value={this.props.product.id == null ? null: this.props.product.id}
            onChange={(event) => this.handleCategoryClick("product", event.target.value) }
            classes={{ root: classes.input }}
          >
          {this.props.products.map((option) => {
                    return (
                      <MenuItem key={option.url} value={option.url}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
          </TextField>
         
        )}
        


        <FormControl variant="filled">
          <FilterList
            aria-owns={this.state.anchorFilter ? "filter-menu" : null}
            classes={{ root: classes.icons }}
            onClick={this.handleClick("anchorFilter")}
          />
        </FormControl>
        <Menu
          id="filter-select"
          anchorEl={this.state.anchorFilter}
          open={Boolean(this.state.anchorFilter)}
          onClose={this.handleClose}
        >
          <MenuItem key={"all"} selected={false}>
            Filter By
          </MenuItem>

          {optionsFilter.map((option) => {
            return (
              <MenuItem
                key={option}
                selected={this.state.filters.indexOf(option) >= 0}
                onClick={(event) =>
                  this.handleFilterMenuItemClick( option)
                }
              >
                {this.state.filters.indexOf(option) >= 0 && (
                  <ListItemIcon className={classes.icon}>
                    <Done />
                  </ListItemIcon>
                )}
                <ListItemText
                  classes={{ primary: classes.primary }}
                  inset
                  primary={option}
                />
              </MenuItem>
            );
          })}
        </Menu>

        {this.state.filters.indexOf("Holidays") >= 0 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.holiday}
              onChange={this.handleChangeHoliday}
              value="holiday"
            />
          }
          label="Holidays"
        />
        )}
        <div style={{ float: "right" }}>
          <IconButton
            aria-label="More"
            aria-owns={open ? "long-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick("anchorEl")}
          >
            <Settings />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 5.5,
              },
            }}
          >
            {optionsLive.map((option) => (
              <MenuItem
                key={option}
                selected={option === String(this.props.liveTime)}
                onClick={() => this.handleCloseLive(option)}
              >
                {option} m
              </MenuItem>
            ))}
          </Menu>
        </div>
        <Tooltip title="Live refresh ">
          <IconButton
            onClick={this.handleToggleTimer}
            style={{ float: "right" }}
            color={this.props.live ? "secondary" : "inherit"}
            aria-label="Close"
          >
            <Timer />
          </IconButton>
        </Tooltip>
        <div>
        <Typography
              variant="caption"
              gutterBottom
              style={{ width: "auto", display: "inline", fontWeight: "lighter" }}
            >
              {this.props.startDate.format("MMMM Do YYYY, h:mm a")}
              {" - "}
              {this.props.endDate.format("MMMM Do YYYY, h:mm a")}
            </Typography>
        <Typography
              variant="caption"
              gutterBottom
              style={{ width: "auto", float:"right", fontWeight: "lighter" }}
            >
              Last Refresh: {this.state.lastRefresh}
      
        </Typography>



        </div>

      </div>
    


    );
  }
}

Calendar.defaultProps = {
  showPerDay: true,
  showGroupBy: true,
};

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  groupBy: PropTypes.string,
  optionPerDay: PropTypes.string,
  timeSelect: PropTypes.string,
  live: PropTypes.bool,
  holiday: PropTypes.bool,
  changeDate: PropTypes.func,
  changeLive: PropTypes.func,

  showPerDay: PropTypes.bool,
  showGroupBy: PropTypes.bool,
  refresh: PropTypes.func,
  liveTime: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    liveTime: state.calendar.liveTime,
    startDate: state.calendar.startDate,
    endDate: state.calendar.endDate,
    groupBy: state.calendar.groupBy,
    optionPerDay: state.calendar.optionPerDay,
    timeSelect: state.calendar.timeSelect,
    live: state.calendar.live,
    holiday: state.calendar.holiday,
    device: state.calendar.device,
    devices: state.calendar.devices,
    employee: state.calendar.employee,
    employees: state.calendar.employees,
  };
}

const mapDispatchToProps = {
  changeDate,
  changeLive,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(CalendarStyle)(Calendar));
