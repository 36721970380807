import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    FormControl,
    InputLabel,
    FormHelperText
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin:  theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});


class AddTabModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           name:""
        };
        
    }


    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }
    close = () => {
        this.setState({name:''})
        this.props.close()
    }
    save = () => {
        this.setState({name:''})
        this.props.save(this.state.name)
    }


    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    maxWidth={'lg'}
                    open={this.props.open}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title">Add Tab</DialogTitle>
                    <DialogContent>
                        
                        <form className={classes.root} autoComplete="off">
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink htmlFor="label-name">
                                    Name:
                                </InputLabel>
                            
                                <TextField
                                    id="label-name"
                                    label="Name"
                                    value={this.state.name}
                                    onChange={this.handleChange('name')}
                                    margin="normal"
                                    />
                                <FormHelperText></FormHelperText>
                            </FormControl>
                        </form>
                                   
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.close} >
                            Close
                        </Button>
                        <Button onClick={this.save} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


AddTabModal.defaultProps = {
    open: false,
};

AddTabModal.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    classes: PropTypes.object.isRequired,
    model: PropTypes.string
};

export default withStyles(styles)(AddTabModal);
