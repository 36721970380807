import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChartCard, Table } from 'components';
import dashboardStyle from 'assets/jss/material-dashboard-react/dashboardStyle';
import { withStyles, TableRow, TableCell } from '@material-ui/core';
import {connect} from 'react-redux';
import axios from 'axios';
import consts from '../../const';



class TotalSalesChartCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data:null,

        };
        // if(props.obj != undefined){
        //     this.state['selectedId'] =  props.obj.productObj.url;
        // }
        this.fetchData = this.fetchData.bind(this);
    }
    componentDidMount() {
        this.fetchData();
    }
    componentDidUpdate(prevProps, prevState) {
        if( prevProps.obj !== this.props.obj && this.props.obj !==   undefined){
            this.setState({selectedId:this.props.obj.productObj.url});
            return;
        }
        if (prevProps.endDate !== this.props.endDate ||
            prevProps.startDate !== this.props.startDate ||
            prevProps.groupBy !== this.props.groupBy ||
            prevProps.holiday !== this.props.holiday ||
            prevProps.optionPerDay !== this.props.optionPerDay ||
            prevProps.employee !== this.props.employee ||
            prevProps.device !== this.props.device ||
            prevProps.timeSelect !== this.props.timeSelect ||
            prevState.selectedId !== this.state.selectedId
        ) {
            let data = { loading: this.props.timeSelect === 'All', };
            // if( this.props.obj !==   undefined){
            //     data['selectedId'] =  this.props.obj.productObj.url;
            // }
            this.setState( data, () => {
                this.fetchData(this.props.timeSelect === 'All');
            });
        }
    }

    getStadistics(){
        if(this.state.data != null && (this.state.selectedId !== undefined || this.state.selectedId !== null )){
            return this.state.data;
        }
        return this.props.data.stadistics.data
    }
    fetchData(allData) {
        let dates = {};

        if (!allData) {
            dates = {
                startDate: this.props.startDate.format(),
                endDate: this.props.endDate.format()
            };
        }

        if (this.props.optionPerDay !== 'all days') {
            dates['peerday'] = this.props.optionPerDay;
        }
        if (this.props.holiday !== false) {
            dates['holiday'] = true;
        }

        if (this.props.employee !== 'all') {
            dates['employee'] = this.props.employee.id;
        }
        if (this.props.device !== 'all') {
            dates['device'] = this.props.device.id;
        }

        let headers = {
            Authorization: `JWT ${localStorage.getItem('token')}`
        };

        dates['groupby'] = this.props.groupBy;
        if(this.props.obj != undefined && this.state.selectedId !== undefined) {
            let url_array = this.state.selectedId.split('/');
            dates['category'] = url_array[url_array.length-2];
        }

        axios.get(consts.API_BASE_URL + '/api/business/stadistics?bytime=1', {params: dates, headers})
            .then(({data}) => {
                this.setState({
                    data: data.data,
                    loading: false
                });

            }).catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                    message: true,
                    onMessage: {
                        style: 'warning',
                        msg: 'Loading errorr, please try again.',
                    }
                });
            });
    }

    sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    render() {
        let listModel = ['bar', 'line'];

        const listCharts = [
            'TotalSales',
            'Transactions',
            'AverageTicket',
            'NetSalesByHour',
            'RevenuePerCategory',
            'Top20RevenuePerItem',
            'Top20RevenuePerCategory',
            'Top20CategoriesSold',
            'SalesByEmployee',
            'Top20ItemsSold',
            'ProductByHour'
        ];

        
        let title = 'Total Sales';
        return <ChartCard
        chartData={{ 
            bar: [{
                type: 'bar',
                x: this.getStadistics().map((obj) => obj['created']),
                y: this.getStadistics().map((obj) => obj['total']),
                name: 'Net Payment'

            }, {
                x: this.getStadistics().map((obj) => obj['created']),
                y: this.getStadistics().map((obj) => obj['count']),
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: 'red' },
                name: 'N° of orders',
                yaxis: 'y2'
            }],
            line: [{
                x: this.getStadistics().map((obj) => obj['created']),
                y: this.getStadistics().map((obj) => obj['total']),
                name: 'Net Payment'
            },{
                x: this.getStadistics().map((obj) => obj['created']),
                y: this.getStadistics().map((obj) => obj['count']),
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: 'red' },
                name: 'N° of orders',
            }
            ]
        }}
        chartLayout={{
            bar: {
                yaxis: {
                    tickformat: '$',
                    side: 'left'
                },
                yaxis2: {
                    overlaying: 'y',
                    side: 'right'
                }
            },
            markers: {
                // height: 400,
                // width: 500
            }
        }}
        showlegend={this.props.showlegend}
        haveDialogTable={true}
        table={
            <Table
                tableheadercolor="primary"
                tablehead={[
                    { 'column': 'name', 'label': 'Created' },
                    { 'column': 'count', 'label': 'Count' },
                    { 'column': 'total', 'label': 'Total' },
                ]}
                orderBy={'total'}
                order={'desc'}
                tabledata={this.sortByKey(this.getStadistics().slice(), 'total').reverse()}
                tablerowrender={(product, key) => {
                    return (
                        <TableRow key={key} onClick={() => this.handleDetailDialogObject(product)}>
                            <TableCell>{product.created}</TableCell>
                            <TableCell>{product.count}</TableCell>
                            <TableCell>{product.total}</TableCell>
                        </TableRow>
                    );
                }}
            />
        }
        title="Total Sales (Net Revenue)"
        haveVisibilityButton={this.state.edit}
        isDrag={this.props.isDrag}
        draggable={this.props.draggable}
        id={this.props.elem} 
        openConfig={this.props.openConfig} 
        toolBox={this.props.toolBox} 
        model={this.props.model || listModel[0]}
        obj={this.props.obj}
        listModel={listModel}
        listCharts={listCharts}
        showTable={this.props.showTable}
        selectData={this.props.data.stadisticsCategory}
        selectedId={this.state.selectedId}
        selectedChange={(selectedOption) => this.setState({selectedId: selectedOption.value})}
    />;
    }
}

TotalSalesChartCard.defaultProps = {
    haveVisibilityButton: false,
    toolBox: true,
    showTable:false
};

TotalSalesChartCard.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.object.isRequired,
    ]),
    haveVisibilityButton: PropTypes.bool,
    refProp: PropTypes.node,
    elem: PropTypes.string,
    isDrag: PropTypes.func,
    draggable: PropTypes.bool,
    openConfig: PropTypes.func,
    toolBox: PropTypes.bool,
    obj: PropTypes.object,
    model: PropTypes.string,
    getListModel: PropTypes.any,
    chartLayoutProps: PropTypes.any,
    showTable: PropTypes.bool,
    showlegend: PropTypes.bool,

    handleDetailDialogObject: PropTypes.func,

    startDate: PropTypes.object,
    endDate: PropTypes.object,
    optionPerDay: PropTypes.string,
    holiday: PropTypes.bool,
    employee: PropTypes.string,
    device: PropTypes.string,
    groupBy: PropTypes.string,
    timeSelect: PropTypes.string,
};


function mapStateToProps(state) {
    return {
        startDate: state.calendar.startDate,
        endDate: state.calendar.endDate,
        holiday: state.calendar.holiday,
        optionPerDay: state.calendar.optionPerDay,
        groupBy: state.calendar.groupBy,
        timeSelect: state.calendar.timeSelect,
        employee: state.calendar.employee,
        device: state.calendar.device,
        live: state.calendar.live
    };
}

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyle)(TotalSalesChartCard));