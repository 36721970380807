import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChartCard, Table } from 'components';
import ProductByHourChartCard from './ProductByHourChartCard';
import { CloudDownload } from '@material-ui/icons';
import dashboardStyle from 'assets/jss/material-dashboard-react/dashboardStyle';
import { withStyles, TableRow, TableCell, IconButton } from '@material-ui/core';
import Workbook from 'react-excel-workbook';
import SalesPerTime from '../Charts/SalesPerTime';
import SalesPerItem from '../Charts/SalesPerItem';
import consts from '../../const';
import TotalSalesCard from './TotalSalesCard';


class FixedChardCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: true,
            openDetail: false,
            detailData: false,
            selectedId:''
        };
        this[this.props.chartType] = React.createRef();
    }
    handleCloseDetail = () => {
        this.setState({ openDetail: false });
    };
    handleDetailDialog = (event) => {
        if(this.props.chartType === 'Top20RevenuePerItem')
            this.setState({ openDetail: true, detailData:this.props.data.stadisticsProduct.data[event.points[0].pointIndex] });
        else if(this.props.chartType === 'Top20CategoriesSold'){
            let array = this.props.data.stadisticsCategory;
            array = this.sortByKey(array, 'count').reverse();
            array = array.slice(0, 20);

            this.setState({ openDetail: true, detailData:array[event.points[0].pointIndex] });
        }
        
    };

    handleDetailDialogObject = (obj) => {
        this.setState({ openDetail: true, detailData:obj});
    };

    sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    
    getSizeCategoryChart(data) {
        let sizes = data.map((obj) => obj['total']);
        var max = Math.max.apply(Math, sizes) / 100;
        // testing size idea**** EH
        const testSize = data.map((obj) => {
            obj['size'] = Math.ceil(obj['total'] / max);
            return obj;
        });

        return data.map((obj) => {
            obj['size'] = Math.ceil(obj['total'] / max);
            return obj;
        });
    }

    getRevenueCategory( stadisticsCategory, type) {
        let array = stadisticsCategory;
        array = this.sortByKey(array, 'total').reverse();            
        array = array.slice(0, 20);
        if(type == 'pie'){
            return [
                {
                    values: array.map((obj) => obj['total']),
                    labels: array.map((obj) => obj['name']),
                    type: 'pie',
                    marker: {
                        colors: array.map((obj) => obj['color']),
                    }
                },
            ];
        }
        return [
            {
                y: array.map((obj) => obj['total']),
                x: array.map((obj) => obj['name']),
                type: type,
                marker: {
                    colors: array.map((obj) => obj['color']),
                }
            },
        ];
    }

    getRevenueItem(selectId, stadisticsProduct, stadisticsCategory, type) {
        let array = stadisticsProduct;
        array = this.sortByKey(array, 'total').reverse();
        if(selectId != null && selectId != '')
            array = array.filter((obj) => obj.categories[0] == selectId.value);
        array = array.slice(0, 20);

        if(type === 'pie'){
            return [{
                values: array.map((obj) => obj['total']),
                labels: array.map((obj) => obj['name']),
                type: 'pie',
                marker: {
                    colors: array.map((obj) => obj['color']),
                }
            }];
        }else if(type == 'line'){
            return [{
                y: array.map((obj) => obj['total']),
                x: array.map((obj) => obj['name']),
                type: 'line',
                marker: {
                    colors: array.map((obj) => obj['color']),
                }
            }];
        }

        return [{
            y: array.map((obj) => obj['total']),
            x: array.map((obj) => obj['name']),
            type: 'bar',
            transforms: [{
                type: 'groupby',
                groups: array.map(product => {
                    try {
                        return stadisticsCategory.find(obj => product.categories[0] === obj.url).name;
                    } catch (err) {
                        return null;
                    }
                }),
                styles: stadisticsCategory.map((obj) => {
                    return {
                        target: obj.name,
                        value: { marker: { color: obj.color } }
                    };
                })
            }]
        }];
    }
    getNumberCategorySold(stadisticsCategory, type) {
        let array = stadisticsCategory;
        array = this.sortByKey(array, 'count').reverse();
        array = array.slice(0, 20);
        if(type == 'pie'){
            return [
                {
                    values: array.map((obj) => obj['count']),
                    labels: array.map((obj) => obj['name']),
                    type: type,
                    marker: {
                        colors: array.map((obj) => obj['color']),
                    }
                },
            ];
        }
        return [
            {
                y: array.map((obj) => obj['count']),
                x: array.map((obj) => obj['name']),
                type: type,
                marker: {
                    color: array.map((obj) => obj['color']),
                }
            },
        ];
    }

    getNumberItemSold(selectId, stadisticsProduct, stadisticsCategory, type) {
        let array = stadisticsProduct;
        array = this.sortByKey(array, 'count').reverse();
        // let testArray = [{name: 'name1',count:40},{name: 'name2',count:20},{name: 'name3',count:30}]; 
        // testArray = this.sortByKey(testArray, 'count').reverse();
        // it was missing && in next line
        if(selectId != null&&selectId != ''){
            if(typeof selectId == 'object')
                array = array.filter((obj) => obj.categories[0] == selectId.value);
            else
                array = array.filter((obj) => obj.categories[0] == selectId);
        }
        array = array.slice(0, 20);
        if(type === 'pie')
            return [
                {
                    values: array.map((obj) => obj['count']),
                    labels: array.map((obj) => obj['name']),
                    type: type,
                    marker: {
                        colors: array.map((obj) => obj['color']),
                    }
                },
            ];


        //****************** looking at transformations for type bar

        let testTransforms = [];    
        testTransforms = [{
            type: 'groupby',
            groups: array.map(product => {
                try {
                    return stadisticsCategory.find(obj => product.categories[0] === obj.url).name;
                } catch (err) {
                    return null;
                }
            }),
            styles: stadisticsCategory.map((obj) => {
                return {
                    target: obj.name,
                    value: { marker: { color: obj.color } }
                };
            })
        }];

        let testGroups = array.map(product => {
            try {
                return stadisticsCategory.find(obj => product.categories[0] === obj.url).name;
            } catch (err) {
                return null;
            }
        });

        let  testStyles = stadisticsCategory.map((obj) => {
            return {
                target: obj.name,
                value: { marker: { color: obj.color } }
            };
        });

        // ************** end


            

        if(type === 'bar')
            return [
                {
                    y: array.map((obj) => obj['count']),
                    x: array.map((obj) => obj['name']),
                    type: type,
                    marker: {
                        color: array.map((producto) => {
                            try {
                                return(stadisticsCategory.find(categorie => categorie.url === producto.categories[0]).color);
                            } catch(err){
                                return(null);
                            }
                         
                        })
                    },

                    // transforms: [{
                    //     type: 'groupby',
                    //     groups: array.map(product => {
                    //         try {
                    //             return stadisticsCategory.find(obj => product.categories[0] === obj.url).name;
                    //         } catch (err) {
                    //             return null;
                    //         }
                    //     }),
                    //     styles: stadisticsCategory.map((obj) => {
                    //         return {
                    //             target: obj.name,
                    //             value: { marker: { color: obj.color } }
                    //         };
                    //     })
                    // }]
                }
            ];
        else{
            let data = {};
            array.forEach(product => {
                if(data[product.categories[0]] !== undefined){
                    data[product.categories[0]].push(product);
                }else{
                    data[product.categories[0]] = [product];
                }

            });
            
            return Object.keys(data).map( (key) => {
                var list = data[key];
                return {
                    y: list.map((obj) => obj['count']),
                    x: list.map((obj) => obj['name']),
                    mode: 'lines+markers',
                    type: 'scatter',
                    marker: {
                        color: array.map((obj) => obj['color']),
                    }
                };
            });
        }
    }
    filterBycategory = (query) => {
        if(this.state.selectedId != null && this.state.selectedId != undefined && this.state.selectedId != '')
            return query.filter((obj) => obj.categories[0] == this.state.selectedId.value);
        return query;
    }

    renderDetail = () => {
        if( this.state.detailData !== false ){
            if(this.props.chartType === 'Top20RevenuePerItem')
                return <SalesPerTime obj={this.state.detailData} />;
            else if(this.props.chartType === 'Top20CategoriesSold'){
                let data = this.props.data.stadisticsProduct.data;
                let object = this.state.detailData;
                data = this.sortByKey(data, 'count').reverse().filter(product => {
                    try {
                        return this.props.data.stadisticsCategory.find(obj => product.categories[0] === object.url);
                    } catch (err) {
                        return null;
                    }
                });
                return <SalesPerItem data={data} />;
            }
        }
        return (<p></p>);
    }
    render() {
        const {chartType} = this.props;
        let chart, listModel;

        const listCharts = [
            'TotalSales',
            'Transactions',
            'AverageTicket',
            'NetSalesByHour',
            'RevenuePerCategory',
            'Top20RevenuePerItem',
            'Top20RevenuePerCategory',
            'Top20CategoriesSold',
            'SalesByEmployee',
            'Top20ItemsSold',
            'ProductByHour'
        ];



        if (chartType === 'TotalSales') {
            //todo
            listModel = ['bar', 'line'];
            if (this.props.getListModel) {
                this.props.getListModel(listModel);
            }
            chart = <TotalSalesCard
                showlegend={this.props.showlegend}
                title="Total Sales"
                haveVisibilityButton={this.state.edit}
                isDrag={this.props.isDrag}
                draggable={this.props.draggable}
                id={this.props.elem}
                openConfig={this.props.openConfig}
                toolBox={this.props.toolBox}
                model={this.props.model || listModel[0]}
                obj={this.props.obj}
                listModel={listModel}
                data={this.props.data}
                listCharts={listCharts}
                showTable={this.props.showTable}
                haveDialogTable={true}
             />;
        } else if (chartType === 'Transactions') {
            listModel = ['bar', 'line'];
            if (this.props.getListModel) {
                this.props.getListModel(listModel);
            }
            chart = <ChartCard
                chartData={{
                    bar: [{
                        type: 'bar',
                        x: this.props.data.stadistics.data.map((obj) => obj['created']),
                        y: this.props.data.stadistics.data.map((obj) => obj['count']),
                        name: 'N° of orders'
                    }],
                    line: [{
                        type: 'line',
                        x: this.props.data.stadistics.data.map((obj) => obj['created']),
                        y: this.props.data.stadistics.data.map((obj) => obj['count']),
                        name: 'N° of orders'
                    }]
                }}
                chartLayout={{
                    bar: {
                        yaxis2: {
                            overlaying: 'y',
                            side: 'right',
                            tickformat: '$'
                        }
                    },
                    line: {
                        yaxis2: {
                            overlaying: 'y',
                            side: 'right',
                            tickformat: '$'
                        }
                    }
                }}
                title="Transactions"
                showlegend={this.props.showlegend}
                haveVisibilityButton={this.state.edit}
                isDrag={this.props.isDrag}
                draggable={this.props.draggable}
                id={this.props.elem} 
                openConfig={this.props.openConfig} 
                toolBox={this.props.toolBox} 
                // model={this.props.model || listModel[0]}
                model={this.props.data.stadistics.data.length > 1 ? this.props.model || listModel[0]:listModel[0]}
                obj={this.props.obj}
                listModel={listModel}
                listCharts={listCharts}
                showTable={this.props.showTable}
                haveDialogTable={true}
                table={
                    <Table
                        tableheadercolor="primary"
                        tablehead={[
                            { 'column': 'name', 'label': 'Created' },
                            { 'column': 'count', 'label': 'Count' },
                            { 'column': 'total', 'label': 'Total' },
                        ]}
                        orderBy={'total'}
                        order={'desc'}
                        tabledata={this.sortByKey(this.props.data.stadistics.data.slice(), 'total').reverse()}
                        tablerowrender={(product, key) => {
                            return (
                                <TableRow key={key} onClick={() => this.handleDetailDialogObject(product)}>
                                    <TableCell>{product.created}</TableCell>
                                    <TableCell>{product.count}</TableCell>
                                    <TableCell>{product.total}</TableCell>
                                </TableRow>
                            );
                        }}
                    />
                }
            />;
        } else if (chartType === 'AverageTicket') {
            listModel = ['line', 'bar'];
            if (this.props.getListModel) {
                this.props.getListModel(listModel);
            }
            chart = <ChartCard
                chartData={{
                    line: [{
                        x: this.props.data.stadistics.data.map((obj) => obj['created']),
                        y: this.props.data.stadistics.data.map((obj) => Number(obj['mean']).toFixed(2)),
                        type: 'scatter',
                        mode: 'lines+points',
                        marker: { color: 'red' },
                    }], 
                    bar: [{
                        x: this.props.data.stadistics.data.map((obj) => obj['created']),
                        y: this.props.data.stadistics.data.map((obj) => Number(obj['mean']).toFixed(2)),
                        type: 'bar'
                    }]
                }}
                chartLayout={{
                    line: {
                        yaxis: {
                            tickformat: '$.2f'
                        },
                    }
                }}
                showlegend={this.props.showlegend}
                title="Average Ticket (net)"
                haveVisibilityButton={this.state.edit}
                isDrag={this.props.isDrag}
                draggable={this.props.draggable}
                id={this.props.elem} 
                openConfig={this.props.openConfig} 
                toolBox={this.props.toolBox} 
                model={this.props.data.stadistics.data.length > 1 ? this.props.model || listModel[0]:listModel[1]}
                obj={this.props.obj}
                listModel={listModel}
                listCharts={listCharts}
                showTable={this.props.showTable}
                haveDialogTable={true}
                table={
                    <Table
                        tableheadercolor="primary"
                        tablehead={[
                            { 'column': 'name', 'label': 'Created' },
                            { 'column': 'mean', 'label': 'Mean' },
                        ]}
                        orderBy={'total'}
                        order={'desc'}
                        tabledata={this.sortByKey(this.props.data.stadistics.data.slice(), 'mean').reverse()}
                        tablerowrender={(product, key) => {
                            return (
                                <TableRow key={key} onClick={() => this.handleDetailDialogObject(product)}>
                                    <TableCell>{product.created}</TableCell>
                                    <TableCell>{product.mean}</TableCell>
                                </TableRow>
                            );
                        }}
                    />
                }
                
            />;
        } else if (chartType === 'NetSalesByHour') {
            listModel = ['bar', 'line'];
            if (this.props.getListModel) {
                this.props.getListModel(listModel);
            }
            let accumulative = this.props.data.stadistics.bytime.map((obj, index) => {
                let array1 = this.props.data.stadistics.bytime.slice(0, index+1);
                if( index === 0)
                    return obj['total_result'];
                return array1.reduce((accumulator, currentValue) => {
                    if(typeof(accumulator) === 'object')
                        accumulator = accumulator['total_result'];
                    return accumulator + currentValue['total_result'];
                });
            });
            let accumlativePercentage = [];
            if(accumulative.length > 0){
                accumlativePercentage = accumulative.map(obj => Math.ceil(obj/accumulative[accumulative.length-1]*100)/100);
            }
            /*{
                        x: this.props.data.stadistics.bytime.map((obj) => obj['hour']),
                        y: this.props.data.stadistics.bytime.map((obj) => obj['orders']),
                        type: 'scatter',
                        mode: 'lines+markers',
                        marker: { color: 'red' },
                        name: 'N° of orders',
                        yaxis: "y2"
                    },*/
            chart = <ChartCard
                chartData={{
                    bar: [{
                        y: this.props.data.stadistics.bytime.map((obj) => obj['total_result']),
                        x: this.props.data.stadistics.bytime.map((obj) => obj['hour']),
                        name: 'Net Sale',
                        type: 'bar',
                    },  {
                        x: this.props.data.stadistics.bytime.map((obj) => obj['hour']),
                        y: accumlativePercentage,
                        type: 'scatter',
                        mode: 'lines+markers',
                        name: 'Accumulative',
                        yaxis: 'y2'
                    }],
                    line: [{
                        y: this.props.data.stadistics.bytime.map((obj) => obj['total_result']),
                        x: this.props.data.stadistics.bytime.map((obj) => obj['hour']),
                        name: 'Net Sale',
                        type: 'line',
                    },  {
                        x: this.props.data.stadistics.bytime.map((obj) => obj['hour']),
                        y: accumlativePercentage,
                        type: 'scatter',
                        mode: 'lines+markers',
                        name: 'Accumulative',
                        yaxis: 'y2'
                    }]
                }}
                chartLayout={{
                    bar: {
                        yaxis: {
                            tickformat: '$',
                            side: 'left'
                        },
                        yaxis2: {
                            overlaying: 'y',
                            side: 'right'
                        },
                    }
                }}
                showlegend={this.props.showlegend}
                title="Net Sales by Hour"
                haveVisibilityButton={this.state.edit}
                isDrag={this.props.isDrag}
                draggable={this.props.draggable}
                id={this.props.elem} 
                openConfig={this.props.openConfig} 
                toolBox={this.props.toolBox} 
                model={this.props.model || listModel[0]}
                obj={this.props.obj}
                listModel={listModel}
                listCharts={listCharts}
                showTable={this.props.showTable}
                haveDialogTable={true}
                table={
                    <Table
                        tableheadercolor="primary"
                        tablehead={[
                            { 'column': 'hour', 'label': 'Hour' },
                            { 'column': 'total_result', 'label': 'Total' },
                        ]}
                        orderBy={'total_result'}
                        order={'desc'}
                        tabledata={this.sortByKey(this.props.data.stadistics.bytime.slice(), 'hour').reverse()}
                        tablerowrender={(product, key) => {
                            return (
                                <TableRow key={key} onClick={() => this.handleDetailDialogObject(product)}>
                                    <TableCell>{product.hour}</TableCell>
                                    <TableCell>{product.total_result}</TableCell>
                                </TableRow>
                            );
                        }}
                    />
                }
            />;
        } else if (chartType === 'RevenuePerCategory') {
            listModel = ['markers', 'bar', 'line', 'pie'];
            console.log(this.props.data.stadistics);
            // debugger
            let netSales = this.props.data.business.net_revenue;
            // let netSales = this.props.data.stadistics.info.total   
     
            if (this.props.getListModel) {
                this.props.getListModel(listModel);
            }
            let dataRevenueCategory = this.getSizeCategoryChart(this.props.data.stadisticsCategory);
            chart = <ChartCard
                chartData={{
                    markers: [{
                        x: dataRevenueCategory.map((obj) => obj['name']),
                        y: dataRevenueCategory.map((obj) => obj['count']),
                        mode: 'markers',
                        text: dataRevenueCategory.map((obj) =>
                            obj['name'] + ' Net Payment:' + obj['total']),
                        marker: {
                            size: dataRevenueCategory.map((obj) => obj['size']),
                            color: dataRevenueCategory.map((obj) => obj['color']),
                        }
                    }],
                    bar:[{
             
                        x: dataRevenueCategory.map((obj) => obj['name']),
                        y: dataRevenueCategory.map((obj) => obj['total']),
                        type: 'bar',
                        text: dataRevenueCategory.map((obj) =>
                            obj['name'] + ' Net Payment'),
                        marker: {
                            color: dataRevenueCategory.map((obj) => obj['color']),
                        },
                        name:'Net Payment'
                    },{
                        x: dataRevenueCategory.map((obj) => obj['name']),
                        y:dataRevenueCategory.map((obj) => Number(obj['total']/netSales).toFixed(2)),
                        text: dataRevenueCategory.map((obj) =>
                            obj['name'] + ' Averall Percentage'),
                        yaxis: 'y2',
                        name:'Averall Percentage'

                    }],
                    line:[{
                        x: dataRevenueCategory.map((obj) => obj['name']),
                        y: dataRevenueCategory.map((obj) => obj['total']),
                        text: dataRevenueCategory.map((obj) =>
                            obj['name'] + ' Net Payment:' + obj['total']),
                        marker: {
                            color: dataRevenueCategory.map((obj) => obj['color']),
                        },
                        name:'Net Payment'
                    },{
                        x: dataRevenueCategory.map((obj) => obj['name']),
                        y: dataRevenueCategory.map((obj) => obj['total']*100/netSales),
                        text: dataRevenueCategory.map((obj) =>
                            obj['name'] + ' Averall Percentage:' + Number(obj['total']/netSales).toFixed(2)),
                        yaxis: 'y2',
                        name:'Averall Percentage'
                    }],
                    pie:[{
                        labels: dataRevenueCategory.map((obj) => obj['name']),
                        values: dataRevenueCategory.map((obj) => obj['total']),
                        type: 'pie',
                        domain: {
                            row: 0,
                            column: 0
                        },
                        name: 'Net Payment',
                        marker: {
                            colors: dataRevenueCategory.map((obj) => obj['color']),
                        }
                    },
                        // {
                        //     labels: dataRevenueCategory.map((obj) => obj['name']),
                        //     values: dataRevenueCategory.map((obj) => Number(obj['total']*100/netSales).toFixed(2)),
                        //     type: 'pie',
                        //     domain: {
                        //         row: 0,
                        //         column: 1
                        //         },
                        //         name: 'Averall Percentage',
                        //         marker: {
                        //             colors: dataRevenueCategory.map((obj) => obj['color']),
                        //         }
                            
                    // }
                    ],
                }}
                chartLayout={{
                    markers:{
                        margin: {
                            l: 40,
                            r: 30,
                            b: 70,
                            t: 0,
                            pad: 0,
                            autoexpand: true
                        },

       
                        xaxis: {
                            showticklabels: true,
                            tickangle: 45,
                        },
                        yaxis: {
                            // tickformat:"$"
                        },
                    },
                    line: {
                        yaxis: {
                            tickformat: '$',
                            side: 'left'
                        },
                        yaxis2: {
                            tickformat: '%',
                            overlaying: 'y',
                            side: 'right'
                        }
                    },
                    bar: {
                        yaxis: {
                            tickformat: '$',
                            side: 'left'
                        },
                        yaxis2: {
                            tickformat: '%',
                            overlaying: 'y',
                            side: 'right'
                        }
                    },
                    pie:{
                        grid: {rows: 1, columns: 1},
                        
                    }
                }}
                plotStyle={{ height: consts.heightGraph }}
                title="Revenue per Category"
                haveVisibilityButton={this.state.edit}
                isDrag={this.props.isDrag}
                draggable={this.props.draggable}
                showlegend={this.props.showlegend}
                id={this.props.elem} 
                openConfig={this.props.openConfig} 
                toolBox={this.props.toolBox} 
                model={this.props.model || listModel[0]}
                obj={this.props.obj}
                listModel={listModel}
                listCharts={listCharts}
                showTable={this.props.showTable}
                haveDialogTable={true}
                table={ 
                    <Table
                        tableheadercolor="primary"
                        tablehead={[
                            { 'column': 'name', 'label': 'Name' },
                            { 'column': 'count', 'label': 'Count' },
                            { 'column': 'size', 'label': 'Size' },
                        ]}
                        orderBy={'total'}
                        order={'desc'}
                        tabledata={this.sortByKey(dataRevenueCategory.slice(), 'count').reverse()}
                        tablerowrender={(product, key) => {
                            return (
                                <TableRow key={key} onClick={() => this.handleDetailDialogObject(product)}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.count}</TableCell>
                                    <TableCell>{product.size}</TableCell>
                                </TableRow>
                            );
                        }}
                    />
                }
                
            />;
        } else if (chartType === 'Top20RevenuePerItem') {
            listModel = ['bar', 'line', 'pie'];
            if (this.props.getListModel) {
                this.props.getListModel(listModel);
            }
            chart = <ChartCard
                chartData={{
                    bar: this.getRevenueItem(this.state.selectedId,
                        this.props.data.stadisticsProduct.data, 
                        this.props.data.stadisticsCategory,
                        
                    ),
                    line: this.getRevenueItem(this.state.selectedId,
                        this.props.data.stadisticsProduct.data, 
                        this.props.data.stadisticsCategory, 'line'
                    ),
                    pie:this.getRevenueItem(this.state.selectedId,
                        this.props.data.stadisticsProduct.data, 
                        this.props.data.stadisticsCategory, 'pie'
                    ),
                }}
                
                chartLayout={{
                    bar:{
                        margin: {
                            l: 40,
                            r: 30,
                            b: 100,
                            t: 10,
                            pad: 0,
                            autoexpand: true
                        },
                        xaxis: {
                            showticklabels: true,
                            tickangle: 45,
                        },
                        yaxis: {
                            tickformat: '$'
                        },
                    },
                }}
                plotStyle={{ height:consts.heightGraph }}
                title="Top 20 - Items Sold (Revenue)"
                showlegend={this.props.showlegend}
                haveDialogTable={true}
                tableDialogTitle={'Revenue per Item'}
                table={<Table
                    tableheadercolor="primary"
                    tablehead={[
                        { 'column': 'name', 'label': 'Name' },
                        { 'column': 'count', 'label': 'Count' },
                        { 'column': 'total', 'label': 'Total' },
                    ]}
                    orderBy={'total'}
                    order={'desc'}
                    tabledata={this.filterBycategory(this.sortByKey(this.props.data.stadisticsProduct.data.slice(), 'total').reverse())}
                    tablerowrender={(product, key) => {
                        return (
                            <TableRow key={key} onClick={() => this.handleDetailDialogObject(product)}>
                                <TableCell>{product.name}</TableCell>
                                <TableCell>{product.count}</TableCell>
                                <TableCell>{product.total}</TableCell>
                            </TableRow>
                        );
                    }}
                />}
                tableDialogExtra={
                    <Workbook filename="Revenue per Item.xlsx" element={
                        <IconButton color="inherit" aria-label="Close">
                            <CloudDownload />
                        </IconButton>
                    }>
                        <Workbook.Sheet
                            data={this.sortByKey(this.props.data.stadisticsProduct.data.slice(), 'total').reverse()}
                            name="Sheet A">
                            <Workbook.Column label="Name" value="name" />
                            <Workbook.Column label="Count" value="count" />
                            <Workbook.Column label="Total" value="total" />
                        </Workbook.Sheet>
                    </Workbook>
                }
                selectData={this.props.data.stadisticsCategory}
                selectedId={this.state.selectedId}
                selectedChange={(selectedOption) => this.setState({selectedId: selectedOption})}
                haveDetailDialog={true}
                openDetailDialog={this.state.openDetail}
                titleDialogDetail={'Revenue per Hour'}
                handleDetailDialog={this.handleDetailDialog}
                handleCloseDetail={this.handleCloseDetail}
                contentDialogDetail={this.renderDetail}
                haveVisibilityButton={this.state.edit}
                isDrag={this.props.isDrag}
                draggable={this.props.draggable}
                id={this.props.elem} 
                openConfig={this.props.openConfig} 
                toolBox={this.props.toolBox} 
                model={this.props.model || listModel[0]}
                obj={this.props.obj}
                listModel={listModel}
                listCharts={listCharts}
                showTable={this.props.showTable}
                
            />;
        } else if (chartType === 'Top20RevenuePerCategory') {
            listModel = ['bar','line', 'pie'];
            if (this.props.getListModel) {
                this.props.getListModel(listModel);
            }
            chart = <ChartCard
                chartData={{
                    bar: this.getRevenueCategory(this.props.data.stadisticsCategory, 'bar'),
                    line: this.getRevenueCategory(this.props.data.stadisticsCategory, 'line'),
                    pie: this.getRevenueCategory(this.props.data.stadisticsCategory, 'pie'),
                }}
                chartLayout={{
                    bar: {
                        margin: {
                            l: 40,
                            r: 40,
                            b: 50,
                            t: 10,
                            pad: 0,
                            autoexpand: true
                        }, yaxis: {
                            tickformat: '$'
                        },
                    }
                }}
                title="Top 20 - Revenue per Category"
                showlegend={this.props.showlegend}
                haveVisibilityButton={this.state.edit}
                isDrag={this.props.isDrag}
                draggable={this.props.draggable}
                id={this.props.elem} 
                openConfig={this.props.openConfig} 
                toolBox={this.props.toolBox} 
                model={this.props.model || listModel[0]}
                obj={this.props.obj}
                listModel={listModel}
                listCharts={listCharts}
                showTable={this.props.showTable}
                haveDialogTable={true}
                table={
                    <Table
                        tableheadercolor="primary"
                        tablehead={[
                            { 'column': 'name', 'label': 'Name' },
                            { 'column': 'total', 'label': 'Total' },
                        ]}
                        orderBy={'total'}
                        order={'desc'}
                        tabledata={this.sortByKey(this.props.data.stadisticsCategory.slice(), 'total').reverse()}
                        tablerowrender={(product, key) => {
                            return (
                                <TableRow key={key} onClick={() => this.handleDetailDialogObject(product)}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.total}</TableCell>
                                </TableRow>
                            );
                        }}
                    />
                }
            />;
        } else if (chartType === 'Top20CategoriesSold') {
            listModel = ['bar', 'line', 'pie'];
            if (this.props.getListModel) {
                this.props.getListModel(listModel);
            }
            chart = <ChartCard
                chartData={{
                    bar: this.getNumberCategorySold(this.props.data.stadisticsCategory, 'bar'),
                    line: this.getNumberCategorySold(this.props.data.stadisticsCategory, 'line'),
                    pie: this.getNumberCategorySold(this.props.data.stadisticsCategory, 'pie'),
                }}
                chartLayout={{
                    bar: {
                        margin: {
                            l: 40,
                            r: 40,
                            b: 50,
                            t: 10,
                            pad: 0,
                            autoexpand: true
                        },
                    }
                }}
                title="Top 20 - Categories sold"
                haveVisibilityButton={this.state.edit}
                isDrag={this.props.isDrag}
                draggable={this.props.draggable}
                id={this.props.elem} 
                openConfig={this.props.openConfig} 
                toolBox={this.props.toolBox} 
                model={this.props.model || listModel[0]}
                obj={this.props.obj}
                listModel={listModel}
                listCharts={listCharts}
                showlegend={this.props.showlegend}
                haveDetailDialog={true}
                openDetailDialog={this.state.openDetail}
                titleDialogDetail={'Revenue per Product'}
                handleDetailDialog={this.handleDetailDialog}
                handleCloseDetail={this.handleCloseDetail}
                contentDialogDetail={this.renderDetail}
                showTable={this.props.showTable}
                haveDialogTable={true}
                table={ 
                    <Table
                        tableheadercolor="primary"
                        tablehead={[
                            { 'column': 'name', 'label': 'Name' },
                            { 'column': 'count', 'label': 'Count' },
                        ]}
                        orderBy={'total'}
                        order={'desc'}
                        tabledata={this.sortByKey(this.props.data.stadisticsCategory.slice(), 'count').reverse()}
                        tablerowrender={(product, key) => {
                            return (
                                <TableRow key={key} onClick={() => this.handleDetailDialogObject(product)}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.count}</TableCell>
                                </TableRow>
                            );
                        }}
                    />
                }
            />;
        } else if (chartType === 'SalesByEmployee') {
            listModel = ['bar', 'line', 'pie'];
            if (this.props.getListModel) {
                this.props.getListModel(listModel);
            }
            chart = <ChartCard
                chartData={{
                    bar: [
                        {
                            x: this.props.data.employees.map((obj) => obj['name']),
                            y: this.props.data.employees.map((obj) => obj['count_sales']),
                            type: 'scatter',
                            name: 'N° of orders'
                        },
                        {
                            type: 'bar',
                            x: this.props.data.employees.map((obj) => obj['name']),
                            y: this.props.data.employees.map((obj) => obj['total_sales']),
                            yaxis: 'y2',
                            name: 'Net Payment'

                        },
                    ],
                    line: [
                        {
                            x: this.props.data.employees.map((obj) => obj['name']),
                            y: this.props.data.employees.map((obj) => obj['count_sales']),
                            type: 'scatter',
                            name: 'N° of orders'
                        },
                        {
                            type: 'line',
                            x: this.props.data.employees.map((obj) => obj['name']),
                            y: this.props.data.employees.map((obj) => obj['total_sales']),
                            yaxis: 'y2',
                            name: 'Net Payment'

                        },
                    ],
                    pie: [
                        {
                            labels: this.props.data.employees.map((obj) => obj['name']),
                            values: this.props.data.employees.map((obj) => obj['count_sales']),
                            type: 'pie',
                            name: 'N° of orders',
                            domain: {
                                row: 0,
                                column: 0
                            },
                        },
                        {
                            type: 'pie',
                            labels: this.props.data.employees.map((obj) => obj['name']),
                            values: this.props.data.employees.map((obj) => obj['total_sales']),
                            name: 'Net Payment',
                            domain: {
                                row: 0,
                                column: 1
                            },

                        },
                    ],
                }}
                chartLayout={{
                    bar: {
                        barmode: 'group',
                        yaxis2: {
                            overlaying: 'y',
                            side: 'right',
                            tickformat: '$'
                        }
                    },
                    line: {
                        barmode: 'group',
                        yaxis2: {
                            overlaying: 'y',
                            side: 'right',
                            tickformat: '$'
                        }
                    },
                    pie:{
                        grid: {rows: 1, columns: 2}
                    }
                }}
                title="Sales by Employee (Net)"
                showlegend={this.props.showlegend}
                haveVisibilityButton={this.state.edit}
                isDrag={this.props.isDrag}
                draggable={this.props.draggable}
                id={this.props.elem} 
                openConfig={this.props.openConfig} 
                toolBox={this.props.toolBox} 
                model={this.props.model || listModel[0]}
                obj={this.props.obj}
                listModel={listModel}
                listCharts={listCharts}
                showTable={this.props.showTable}
                haveDialogTable={true}
                table={
                    <Table
                        tableheadercolor="primary"
                        tablehead={[
                            { 'column': 'name', 'label': 'Name' },
                            { 'column': 'count_sales', 'label': 'Count' },
                            { 'column': 'total_sales', 'label': 'Sales' },
                        ]}
                        orderBy={'total'}
                        order={'desc'}
                        tabledata={this.sortByKey(this.props.data.employees.slice(), 'count_sales').reverse()}
                        tablerowrender={(product, key) => {
                            return (
                                <TableRow key={key} onClick={() => this.handleDetailDialogObject(product)}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.count_sales}</TableCell>
                                    <TableCell>{product.total_sales}</TableCell>
                                </TableRow>
                            );
                        }}
                    />
                }
            />;
        } else if (chartType === 'Top20ItemsSold') {
            listModel = ['bar', 'line', 'pie'];
            //test EH ***************************************************************
            let testItemsSold = this.getNumberItemSold(this.state.selectedId,
                this.props.data.stadisticsProduct.data, 
                this.props.data.stadisticsCategory, 'bar'
            );
            //*************** 

            if (this.props.getListModel) {
                this.props.getListModel(listModel);
            }
            chart = <ChartCard
                chartData={{
                    bar: this.getNumberItemSold(this.state.selectedId,
                        this.props.data.stadisticsProduct.data, 
                        this.props.data.stadisticsCategory, 'bar'
                    ),
                    line: this.getNumberItemSold(this.state.selectedId,
                        this.props.data.stadisticsProduct.data, 
                        this.props.data.stadisticsCategory, 'line'
                    ),
                    pie: this.getNumberItemSold(this.state.selectedId,
                        this.props.data.stadisticsProduct.data, 
                        this.props.data.stadisticsCategory, 'pie'
                    )
                }}
                title="Top 20 - Items Sold (Quantity)"
                showlegend={this.props.showlegend}
                chartLayout={{
                    bar: {
                        margin: {
                            l: 40,
                            r: 30,
                            b: 100,
                            t: 10,
                            pad: 0,
                            autoexpand: true
                        },

                        xaxis: {
                            showticklabels: true,
                            tickangle: 45,
                        }
                    }
                }}
                haveDialogTable={true}
                tableDialogTitle={'Top Items sold'}
                table={
                    <Table
                        tableheadercolor="primary"
                        tablehead={[
                            { 'column': 'name', 'label': 'Name' },
                            { 'column': 'count', 'label': 'Count' },
                            { 'column': 'total', 'label': 'Total' },
                            { 'column': 'categories', 'label': 'Category' },
                        ]}
                        orderBy={'total'}
                        order={'desc'}
                        tabledata={this.props.data.stadisticsProduct.data}
                        tablerowrender={(product, key) => {
                            let categories = this.props.data.stadisticsCategory.find(obj => product.categories[0] === obj.url);

                            let category = 'No Category';
                            if(categories != undefined){
                                category = categories.name;
                            }
                            return (
                                <TableRow key={key}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.count}</TableCell>
                                    <TableCell>{product.total}</TableCell>
                                    <TableCell>{category}</TableCell>
                                </TableRow>
                            );
                        }}

                    />
                }
                tableDialogExtra={
                    <Workbook filename="Top Items sold.xlsx" element={
                        <IconButton color="inherit" aria-label="Close">
                            <CloudDownload />
                        </IconButton>
                    }>
                        <Workbook.Sheet data={this.props.data.stadisticsProduct.data}
                            name="Sheet A">
                            <Workbook.Column label="Name" value="name" />
                            <Workbook.Column label="Count" value="count" />
                            <Workbook.Column label="Total" value="total" />
                        </Workbook.Sheet>
                    </Workbook>
                }
                selectData={this.props.data.stadisticsCategory}
                selectedId={this.state.selectedId}
                selectedChange={(selectedOption) => this.setState({selectedId: selectedOption})}

                haveVisibilityButton={this.state.edit}
                isDrag={this.props.isDrag}
                draggable={this.props.draggable}
                id={this.props.elem} 
                openConfig={this.props.openConfig} 
                toolBox={this.props.toolBox} 
                model={this.props.model || listModel[0]}
                obj={this.props.obj}
                listModel={listModel}
                listCharts={listCharts}
                showTable={this.props.showTable}
            />;
        } else if (chartType === 'ProductByHour') {
            listModel = ['bar', 'line'];
            if (this.props.getListModel) {
                this.props.getListModel(listModel);
            }

            chart = <ProductByHourChartCard
                showlegend={this.props.showlegend}
                title="Product by Hour"
                haveVisibilityButton={this.state.edit}
                isDrag={this.props.isDrag}
                draggable={this.props.draggable}
                id={this.props.elem}
                openConfig={this.props.openConfig}
                toolBox={this.props.toolBox}
                model={this.props.model || listModel[0]}
                obj={this.props.obj}
                listModel={listModel}
                listCharts={listCharts}
                showTable={this.props.showTable}
                haveDialogTable={true}
                selectData={this.props.data.stadisticsProduct.data}

            />;
        }

        return (
            <div ref={this[chartType]}>
                {chart}
            </div>
        );
    }
}

FixedChardCard.defaultProps = {
    haveVisibilityButton: false,
    toolBox: true,
    showTable:false
};

FixedChardCard.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.object.isRequired,
    ]),
    haveVisibilityButton: PropTypes.bool,
    refProp: PropTypes.node,
    elem: PropTypes.string,
    isDrag: PropTypes.func,
    draggable: PropTypes.bool,
    openConfig: PropTypes.func,
    toolBox: PropTypes.bool,
    model: PropTypes.string,
    getListModel: PropTypes.any,
    chartLayoutProps: PropTypes.any,
    showTable: PropTypes.bool,
    showlegend: PropTypes.bool
};


export default withStyles(dashboardStyle)(FixedChardCard);