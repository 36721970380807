import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

import loginRoutes from 'routes/login.jsx';

import loginStyle from 'assets/jss/material-dashboard-react/loginStyle.jsx';

import logo from 'assets/img/logo.png';

const switchRoutes = (
    <Switch>
        {loginRoutes.map((prop, key) => {
            if (prop.redirect)
                return <Redirect from={prop.path} to={prop.to} key={key} />;
            return <Route path={prop.path} component={prop.component} key={key} />;
        })}
    </Switch>
);

class App extends React.Component {
  state = {
      mobileOpen: false,
      username:'',
      password:'',
  };
  handleDrawerToggle = () => {
      this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
      return this.props.location.pathname !== '/maps';
  }
  componentDidMount() {

  }
  componentDidUpdate() {
      //this.refs.mainPanel.scrollTop = 0;
  }

  render() {
      const { classes } = this.props;
      return (
          <div className={'background'}>
              <div className={classes.wrapper}>
                  <div className={classes.header}>
                      <img src={logo} alt="logo" className={classes.logoImage} />
                  </div>
                  <div className={classes.container}>
                      {switchRoutes}


                  </div>
              </div>

          </div>

      );
  }
}

App.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(loginStyle)(App);
