import moment from 'moment';

const initialState = {
    startDate: moment().set({
        'hour': 0,
        'minute': 0,
        'second': 59
    }),
    endDate: moment().set({
        'hour': 23,
        'minute': 59,
        'second': 59
    }),
    optionPerDay: 'all days',
    groupBy: 'day',
    timeSelect: 'today',
    holiday:false,
    liveTime: 15,
    live: false,
    device:"all",
    devices:[],
    employee:"all",
    employees:[]
};


export default function (state = initialState, actions) {
    switch (actions.type) {
    case 'DATE': 
        return Object.assign({}, state,  {...actions.dates});
    case 'LIVE': 
        return Object.assign({}, state, {
            live: actions.live.live,
            liveTime: actions.live.liveTime
        });
    case 'SAVE_INITIAL':
        console.log(actions)
        return Object.assign({}, state, {
            devices:actions.devices,
            employees:actions.employees,
        });
    default:
        return state;
    }
}
