import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import 'weather-icons/css/weather-icons.css';
import {
    Store, AccountBalance,
    LocalOffer,
    Cloud, PersonAdd, Add
} from '@material-ui/icons';
import {
    withStyles, Grid, 
    AppBar, Tabs, Tab, Fab, 
    Typography
} from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';
import {
    StatsCard,
    RegularCard,
    Loading, ItemGrid, 
    Calendar, MessageSnackbar
} from 'components';
import dashboardStyle from 'assets/jss/material-dashboard-react/dashboardStyle';
import consts from '../../const';
import { ContainerChartCard, ConfigContainerChart } from '../../components';
import  AddTabModal from '../../components/Modal/AddTabModal';
import { connect } from 'react-redux';
import { logout, refresh, logoutTimeout, fetchDataInitial } from '../../redux/actions/auth';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const CURRENT_VERSION=2;
class Dashboard extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            tabValue: 0,
            peerday: '',
            groupBy: 'day',
            message: false,
            onMessage: {
                style: '',
                msg: ''
            },
            draggable: false,
            allData: false,
            loading: true,
            openConfig: false,
            dataTab: [],
            data: {
                business: {},
                stadistics: {data: [], business: [], bytime: []},
                stadisticsProduct: {data: [], byDateTotal: [], byDateCount: []},
                stadisticsCategory: [],
                employees: [],
                temperature: {}
            },
            edit: true,
            anchorEl:null,
            openAdd:false,
            openAddCard: false,
            filters:[],
            showItemsSold:'hola',
            categoriesHidden:[]
        };

        this.fetchData = this.fetchData.bind(this);

    }

    handleChange = (event, tabValue) => {
        this.setState({tabValue});
    }

    handleOpenAddMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleCloseAddMenu = () => {
        this.setState({ anchorEl: null });
    };
    handleAddTab = () => {
        this.setState({ anchorEl: null, openAdd:true });
    };
    handleDeleteTab = () => {
        let datatab = JSON.parse(JSON.stringify(this.state.dataTab));
        delete datatab[this.state.tabValue];
        this.setState({ anchorEl: null, dataTab: datatab.filter((obj) => obj!= null) });
    };
    handleAddCard = () => {
        this.setState({ anchorEl: null, openAddCard:true });
    };
    handleCloseAddTab = () => {
        this.setState({openAdd: false});
    };
    handleSaveAddTab = (name) => {
        this.setState({openAdd: false,
            dataTab:[...this.state.dataTab, {
                id: 'tab-'+this.state.dataTab.length+1,
                label: name,
                rows: []
            } ]});
    };



    handleCloseAddCard = (param) => {
        console.log('handleCloseAddCard');
        
        if(param !== undefined){
            let datatab = JSON.parse(JSON.stringify(this.state.dataTab));
            datatab[this.state.tabValue].rows.push({
                id: 'row-'+datatab[this.state.tabValue].rows.length+1,
                taskIds: [param]
            });

            this.setState({
                openAddCard: false, 
                dataTab: datatab
            });
        }else{
            this.setState({
                openAddCard: false
            });
        }
    };

    getIconTemperature(condition) {
        if (condition === 'partly-cloudy-night')
            return 'wi wi-night-partly-cloudy';
        else if (condition === 'rain')
            return 'wi wi-rain';
        else if (condition === 'partly-cloudy-day')
            return 'wi wi-day-cloudy';
        else if (condition === 'clear-day')
            return 'wi wi-day-sunny';
        else if (condition === 'clear-night')
            return 'wi wi-night-clear';
    }

    sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    
    handleChangeIndex = index => {
        this.setState({value: index});
    };

    componentDidUpdate(prevProps, prevState) {
  
        if (prevProps.endDate !== this.props.endDate ||
            prevProps.startDate !== this.props.startDate ||
            prevProps.groupBy !== this.props.groupBy ||
            prevProps.holiday !== this.props.holiday ||
            prevProps.optionPerDay !== this.props.optionPerDay ||
            prevProps.employee !== this.props.employee ||
            prevProps.device !== this.props.device ||
            prevProps.timeSelect !== this.props.timeSelect 
        ) {
            if (this.props.timeSelect === 'All') {
                this.setState({ loading: true }, () => {
                    this.fetchData(true);
                });
            } else {
                this.setState({loading: true}, () => {
                    this.fetchData();
                });
            }
        }
        if(prevState.dataTab !== this.state.dataTab ){
 
            this.postConfigDashboard(this.state.dataTab);
            localStorage.setItem('dataTab', JSON.stringify(this.state.dataTab));
        }
        if(prevState.filters !== this.state.filters ){

            this.postConfigDashboard(this.state.dataTab);
            localStorage.setItem('filters', JSON.stringify(this.state.filters));
        }
    }
    activedDrag= (draggable ) => {
        this.setState({ draggable});
    }
    handleReorderTab = () => {
        this.setState({ anchorEl: null,draggable:!this.state.draggable});
    }

    refresh = () => {
        this.setState({ 
            loading: true, 
            allData: false 
        }, () => {
            this.fetchData(false);
        });
    }

    fetchData(allData) {
        let dates = {};

        if (!allData) {
            dates = {
                startDate: this.props.startDate.format(),
                endDate: this.props.endDate.format()
            };
        }

        if (this.props.optionPerDay !== 'all days') {
            dates['peerday'] = this.props.optionPerDay;
        }
        if (this.props.holiday !== false) {
            dates['holiday'] = true;
        }

        if (this.props.employee !== 'all') {
            dates['employee'] = this.props.employee.id;
        }
        if (this.props.device !== 'all') {
            dates['device'] = this.props.device.id;
        }

        let headers = {
            Authorization: `JWT ${localStorage.getItem('token')}`
        };

        dates['groupby'] = this.props.groupBy;
        axios.all([
            axios.get(consts.API_BASE_URL + '/api/business/2/', {params: dates, headers}),
            axios.get(consts.API_BASE_URL + '/api/business/stadistics?bytime=1', {params: dates, headers}),
            axios.get(consts.API_BASE_URL + '/api/categories/?fields!=products', {params: dates, headers}),
            axios.get(consts.API_BASE_URL + '/api/products/stadistics/', {params: dates, headers}),
            axios.get(consts.API_BASE_URL + '/api/temperatures/actual/', {params: dates, headers}),
            axios.get(consts.API_BASE_URL + '/api/employees/', {params: dates, headers})
        ]).then(([businessData, businessStadistics, categoriesStadistics,
            productStadistics, temperatureStadistics, employees]) => {
            this.setState({
                data: {
                    business: businessData.data,
                    stadistics: businessStadistics.data,
                    stadisticsCategory: categoriesStadistics.data,
                    stadisticsProduct: productStadistics.data,
                    temperature: temperatureStadistics.data,
                    employees: employees.data,
                },
                loading: false            
            });

        }).catch(error => {
            if(error.response && (error.response.status === 401 || error.response.status === 403) ){
                this.props.logoutTimeout();
                this.props.history.push('/user/login');
            }
            this.setState({
                loading: false,
                message: true, 
                onMessage: {
                    style: 'warning',
                    msg: 'Loading errorr, please try again.',
                }
            });
        });
    }

    getConfigDashboard() {
        // let dataTab = localStorage.getItem('dataTab');
        // let version = localStorage.getItem('dataVersion', 0);
        // let showItemsSold = localStorage.getItem('showItemsSold');
        
        let headers = {
            Authorization: `JWT ${localStorage.getItem('token')}`
        };

        // if(version < CURRENT_VERSION){
        //     dataTab=null;
        // }
        //if(dataTab !== null){
        //    this.setState({dataTab: JSON.parse(dataTab),});
        //}else{
        axios.get(`${consts.API_BASE_URL}/api/users/me`, {headers})
            .then(res => {
                let x = res.data.data.filters === undefined;
                this.setState({
                    dataTab: res.data.data.data !== undefined ? res.data.data.data : [],
                    filters: res.data.data.filters === undefined ? []: res.data.data.filters,
                    showItemsSold: res.data.data.showItemsSold,
                    categoriesHidden: res.data.data.categoriesHidden
                });
                localStorage.setItem('dataTab', JSON.stringify(res.data.data.data));
                localStorage.setItem('dataVersion', JSON.stringify(CURRENT_VERSION));
                localStorage.setItem('filters', JSON.stringify(res.data.data.filters));
                if (res.data.data.showItemsSold != undefined){
                    localStorage.setItem('showItemsSold', JSON.stringify(res.data.data.showItemsSold));
                }
                if (res.data.data.categoriesHidden != undefined){
                    localStorage.setItem('categoriesHidden', JSON.stringify(res.data.data.categoriesHidden));
                }else{
                    localStorage.setItem('categoriesHidden', JSON.stringify([]));
                }
                        
                      
            });
        //}
    }

    postConfigDashboard(data) {

        let headers = {
            Authorization: `JWT ${localStorage.getItem('token')}`
        };
        let d = new Date();
        axios.put(`${consts.API_BASE_URL}/api/users/me`, {data:{data,filters:this.state.filters, version:CURRENT_VERSION, date:d.toUTCString(),showItemsSold:this.state.showItemsSold,categoriesHidden:this.state.categoriesHidden} }, {headers})
            .then(res => {
                console.log(res);
            });
    }

    refreshToken() {
        let credentials = { token: localStorage.getItem('token') };
        axios.post(`${consts.API_BASE_URL}/api/auth/refresh/`, credentials)
            .then(res => {
                this.props.refresh(res.data.token);
            });
    }

    componentDidMount() {
        this.fetchData();
        // this.postConfigDashboard([])
        this.getConfigDashboard();
        this.props.fetchDataInitial();
 
        //this.refreshToken();
        document.title = 'Dashboard';
    }

    closeConfig = (param) => {
        console.log(param);
        console.log('closeConfig');
        if(param !== undefined){
            let datatab = JSON.parse(JSON.stringify(this.state.dataTab));
            if(datatab[this.state.tabValue].id !== param.tab){
                datatab[this.state.tabValue].rows.forEach((row, indexRow) => {
                    let index = row.taskIds.findIndex((item) => item.id === param.id);
                    if(index > -1)
                        datatab[this.state.tabValue].rows[indexRow].taskIds.splice(index, 1);
                });
                let tab = datatab.find((item) => param.tab == item.id);
                tab.rows.push({
                    id: 'row-'+tab.rows.length+1,
                    taskIds: [param]
                });
            }else{
                console.log(param);
                datatab[this.state.tabValue].rows.forEach((row, indexRow) => {
                    let index = row.taskIds.findIndex((item) => {
                        if (item!==null) 
                            return item.id === param.id;
                        else
                            return false;
                    });
      
                    if(index > -1)
                        datatab[this.state.tabValue].rows[indexRow].taskIds[index] = param;
                });
                
            }
            this.setState({
                openConfig: false, 
                dataTab: datatab
            });
        }else{
            this.setState({
                openConfig: false
            });
        }
    }

    handleClickOpenConfig = (action, chartId, model, obj) => {
        console.log(chartId);
        if(action == 'config')
            this.setState({ 
                openConfig: true,
                chartId,
                model, 
                obj
            });
        else if(action == 'delete'){
            console.log(action);
            console.log(chartId);
            console.log(obj);
            let datatab = JSON.parse(JSON.stringify(this.state.dataTab));
            datatab[this.state.tabValue].rows.forEach((row, indexRow) => {
                let index = row.taskIds.findIndex((item) => item.id === obj.id);
                if(index > -1){
                    delete datatab[this.state.tabValue].rows[indexRow].taskIds[index];
                    datatab[this.state.tabValue].rows[indexRow].taskIds = datatab[this.state.tabValue].rows[indexRow].taskIds.filter((obj) => obj!= null);
                }
            });
            this.setState({
                openConfig: false, 
                dataTab: datatab
            });
        }
    };
    handleChangeRows = (rows) => {
        let datatab = JSON.parse(JSON.stringify(this.state.dataTab));
        datatab[this.state.tabValue].rows = rows;

        this.setState({
            dataTab: datatab
        });
    }

    render() {
        var state = this.state;
        const tabValue = this.state.tabValue;
        const { classes } = this.props;
        return (
            
            <div>
                <Loading
                    open={this.state.loading}
                />
                <MessageSnackbar
                    open={this.state.message}
                    style={this.state.onMessage.style}
                    msg={this.state.onMessage.msg}
                    close={() => this.setState({message: false})}
                />
                {this.state.openConfig && <ConfigContainerChart
                    open={this.state.openConfig}
                    close={this.closeConfig}
                    dataConfig={this.state.dataTab}
                    tab={this.state.dataTab[tabValue].id}
                    data={this.state.data}
                    chartId={this.state.chartId}
                    model={this.state.model}
                    obj={this.state.obj}
                />}

                <Grid container className={classes.stickTop}>
                    <ItemGrid xs={12} sm={12} md={9}>
                        <RegularCard
                            content={
                                <Calendar
                                    refresh={this.refresh}
                                />
                            }
                        />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={3}>
                        <StatsCard
                            icon={Cloud}
                            iconColor="orange"
                            title="Temperature"
                            styleCard={{paddingTop:'7px', paddingBottom:'6px'}}
                            description={
                                <span><i
                                    className={this.getIconTemperature(this.state.data.temperature.condition)}></i>
                                {this.state.data.temperature.thermometer || 0}°</span>}
                        />
                    </ItemGrid>
                </Grid>
                <Grid container>
                    <ItemGrid xs={12} sm={6} md={3}>
                        <StatsCard
                            icon={Store}
                            iconColor="orange"
                            title="Net revenue"
                            description={
                                <CurrencyFormat value={this.state.data.business.net_revenue || 0}
                                    displayType={'text'} thousandSeparator={true}
                                    prefix={'$'}/>
                            }
                            statText={this.state.data.business.number_orders + ' orders'}
                            statAlternativeText={
                                <span>
                                    <CurrencyFormat
                                        decimalScale={2}
                                        value={(this.state.data.business.gross_sales) || 0}
                                        displayType={'text'} thousandSeparator={true}
                                        prefix={'$'}/>{' '}
                                    Gross Sales
                                </span>}
                        />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={3}>
                        <StatsCard
                            icon={AccountBalance}
                            iconColor="green"
                            title="Sales tax"
                            description={
                                <CurrencyFormat
                                    value={this.state.data.business.sales_tax || 0}
                                    displayType={'text'} thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={'$'}/>
                            }
                            statText="Tax of 7%"
                        />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={3}>
                        <StatsCard
                            icon={PersonAdd}
                            iconColor="red"
                            title="Tips"
                            description={
                                <span>
                                    {state.data.business.tip_amount ? (
                                        <CurrencyFormat
                                            value={this.state.data.business.tip_amount || 0}
                                            decimalScale={2}
                                            displayType={'text'}
                                            thousandSeparator={true} prefix={'$'}/>)
                                        : 0}
                                </span>

                            }
                            statText={this.state.data.business.number_employees + ' employees'}
                        />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={3}>
                        <StatsCard
                            icon={LocalOffer}
                            iconColor="blue"
                            title="Ticket Average"
                            description={
                                <CurrencyFormat
                                    value={this.state.data.business.ticket_average || 0}
                                    displayType={'text'}
                                    thousandSeparator={true} prefix={'$'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                            }
                            statText={
                                <span>
                                    <CurrencyFormat
                                        value={this.state.data.business.refund_amount || 0}
                                        displayType={'text'}
                                        thousandSeparator={true} prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                    {' '}Refund amount
                                </span>}
                        />
                    </ItemGrid>
                </Grid>
                <div style={{position:'relative'}}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={tabValue}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {
                                this.state.dataTab!==undefined&&
                            this.state.dataTab.filter((obj) => obj!= null).map((item, index) => (
                                <Tab label={item.label} key={index}/>
                            ))}
                        </Tabs>
                        
                    </AppBar>
                    <Fab size="small" color="primary"  className={classes.addIcon} 
                        aria-owns={this.state.anchorEl ? 'menu-add' : undefined}
                        onClick={this.handleOpenAddMenu}
                        aria-haspopup="true">
                        <Add className={classes.extendedIcon} />
                    </Fab>
                    <Menu
                        id="menu-add"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleCloseAddMenu}
                    >
                        <MenuItem onClick={this.handleAddTab}>Add Tab</MenuItem>
                        <MenuItem onClick={this.handleAddCard}>Add Graph</MenuItem>
                        <MenuItem onClick={this.handleDeleteTab}>Delete Tab</MenuItem>
                        <MenuItem onClick={this.handleReorderTab}>Reorder Tab</MenuItem>
                    </Menu>

                    <AddTabModal 
                        open={this.state.openAdd}
                        close={this.handleCloseAddTab}
                        save={this.handleSaveAddTab}
                    />
                    <ConfigContainerChart
                        open={this.state.openAddCard}
                        close={this.handleCloseAddCard}
                        data={this.state.data}
                        tab={tabValue}
                        newChart={true}
                        dataConfig={this.state.dataTab}
                    />

                    {this.state.dataTab !== undefined && this.state.dataTab[tabValue] !== undefined && (
                        <div key={tabValue}>
                            <TabContainer key={tabValue}>
                                <ContainerChartCard 
                                    dataConfig={this.state.dataTab[tabValue].rows}
                                    data={this.state.data}
                                    openConfig={this.handleClickOpenConfig}
                                    changeRows={this.handleChangeRows}
                                    draggable={this.state.draggable}
                                    isDrag={this.activedDrag}
                                />
                            </TabContainer>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    optionPerDay: PropTypes.string,
    groupBy: PropTypes.string,
    timeSelect: PropTypes.string,
    live: PropTypes.bool,
    isDrag: PropTypes.func,
    draggable: PropTypes.bool,

};

function mapStateToProps(state) {
    return {
        startDate: state.calendar.startDate,
        endDate: state.calendar.endDate,
        holiday: state.calendar.holiday,
        optionPerDay: state.calendar.optionPerDay,
        groupBy: state.calendar.groupBy,
        timeSelect: state.calendar.timeSelect,
        employee: state.calendar.employee,
        device: state.calendar.device,
        live: state.calendar.live
    };
}

const mapDispatchToProps = {logout, refresh, logoutTimeout, fetchDataInitial};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyle)(Dashboard));
