import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    FormControl,
    Input,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import { ItemGrid, FixedChardCard } from 'components';
import humanize from 'underscore.string/humanize';
import equals from 'array-equal';
import { v4 as uuidv4 } from 'uuid';


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const listCharts = [
    'TotalSales',
    'Transactions',
    'AverageTicket',
    'NetSalesByHour',
    'RevenuePerCategory',
    'Top20RevenuePerItem',
    'Top20RevenuePerCategory',
    'Top20CategoriesSold',
    'SalesByEmployee',
    'Top20ItemsSold',
    'ProductByHour'
];

class ConfigContainerChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartId: this.props.chartId,
            model: this.props.model,
            category: '',
            product: '',
            tab: this.props.tab,
            listModel: [],
            name:'',
            showlegend:false,
            showTable:false,
        };
        this.getListModel = this.getListModel.bind(this);

    }
    handleChange = name => event => {
        console.log();
        if( event.target.checked != undefined){
            this.setState({ [name]: event.target.checked });
        }else
            this.setState({ [name]: event.target.value });
    }

    getListModel(listModel) {
        if (!equals(this.state.listModel, listModel)) {
            this.setState({
                listModel: listModel,
                model: listModel[0]
            });
        }
    }
    handleClose = () => {
        
        if(this.props.obj != undefined){
            this.props.close({
                id:this.props.obj.id,
                chartId:this.state.chartId,
                chart1:{model:this.state.model},
                tab:this.state.tab,
                showlegend:this.state.showlegend,
                showTable:this.state.showTable,
                category:this.state.category,
                product:this.state.product,
                productObj:this.props.data.stadisticsProduct.data.find((n) => n.id === this.state.product)
            });
        }else
            this.props.close({
                id:uuidv4(),
                chartId:this.state.chartId,
                chart1:{model:this.state.model},
                showlegend:this.state.showlegend,
                category:this.state.category,
                product:this.state.product,
                productObj:this.props.data.stadisticsProduct.data.find((n) => n.id === this.state.product)
            });
    }
       
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={this.props.open}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title">Config Chart Card</DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <ItemGrid md={8}>
                                <FixedChardCard
                                    elem={this.state.chartId}
                                    chartType={this.state.chartId}
                                    model={this.state.model}
                                    data={this.props.data}
                                    draggable={false}
                                    toolBox={false}
                                    showlegend={this.state.showlegend}
                                    showTable={this.state.showTable}
                                    getListModel={this.getListModel}
                                />
                            </ItemGrid>
                            <ItemGrid md={4}>
                                <Grid container>
                                    <ItemGrid md={12}>
                                        <form className={classes.root} autoComplete="off">
                                            {/*this.props.newChart == true && (
                                        <FormControl className={classes.formControl} fullWidth={true}>
                                            <TextField
                                                id="label-name"
                                                label="Name"
                                                value={this.state.name}
                                                onChange={this.handleChange('name')}
                                                margin="normal"
                                                fullWidth={true}
                                                />
                                            <FormHelperText></FormHelperText>
                                        </FormControl> )*/} 
                                            <FormControl className={classes.formControl} fullWidth={true}>
                                                <InputLabel shrink htmlFor="label-chart">
                                                    Chart:
                                                </InputLabel>
                                                <Select
                                                    value={this.state.chartId}
                                                    onChange={this.handleChange('chartId')}
                                                    input={<Input name="chart" id="label-chart" />}
                                                    displayEmpty
                                                    className={classes.selectEmpty}
                                                    fullWidth={true}
                                                >
                                                    {listCharts.map((item, index) => (
                                                        <MenuItem value={item} key={index}>{humanize(item)}</MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText></FormHelperText>
                                            </FormControl>
                                        
                                            <FormControl className={classes.formControl} fullWidth={true}>
                                                <InputLabel shrink htmlFor="model-chart">
                                                    Type Chart:
                                                </InputLabel>
                                                <Select
                                                    value={this.state.model}
                                                    onChange={this.handleChange('model')}
                                                    input={<Input name="type" id="model-chart" />}
                                                    displayEmpty
                                                    name="model"
                                                    className={classes.selectEmpty}
                                                    fullWidth={true}
                                                >
                                                    {this.state.listModel.map((item, index) => (
                                                        <MenuItem value={item} key={index}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText></FormHelperText>
                                            </FormControl>
                                            {this.state.chartId === 'ProductByHour' && (
                                                <div>
                                                    <FormControl className={classes.formControl} fullWidth={true}>
                                                        <InputLabel shrink htmlFor="model-category">
                                                    Category:
                                                        </InputLabel>
                                                        <Select
                                                            value={this.state.category}
                                                            onChange={this.handleChange('category')}
                                                            input={<Input name="type" id="model-category" />}
                                                            displayEmpty
                                                            name="category"
                                                            className={classes.selectEmpty}
                                                            fullWidth={true}
                                                        >
                                                            {this.props.data.stadisticsCategory.map((item, index) => (
                                                                <MenuItem value={item.url} key={index}>{item.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl className={classes.formControl} fullWidth={true}>
                                                        <InputLabel shrink htmlFor="model-product">
                                                    Product:
                                                        </InputLabel>
                                                        <Select
                                                            value={this.state.product}
                                                            onChange={this.handleChange('product')}
                                                            input={<Input name="type" id="model-product" />}
                                                            displayEmpty
                                                            name="product"
                                                            className={classes.selectEmpty}
                                                            fullWidth={true}
                                                        >
                                                            {this.props.data.stadisticsProduct.data.filter((n) =>
                                                                n.categories.includes(this.state.category)
                                                            ).map((item, index) => (
                                                                <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            )}
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={this.state.showlegend} onChange={this.handleChange('showlegend')} />
                                                }
                                                label="Show Legend"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={this.state.showTable} onChange={this.handleChange('showTable')} />
                                                }
                                                label="Show Table"
                                            />
                                            {!this.props.newChart == true && (
                                                <FormControl className={classes.formControl} fullWidth={true}>
                                                    <InputLabel shrink htmlFor="tab-chart">
                                                    Tab:
                                                    </InputLabel>
                                                    <Select
                                                        value={this.state.tab}
                                                        onChange={this.handleChange('tab')}
                                                        input={<Input name="type" id="tab-chart" />}
                                                        displayEmpty
                                                        name="tab"
                                                        className={classes.selectEmpty}
                                                        fullWidth={true}
                                                    >
                                                        {this.props.dataConfig != undefined && this.props.dataConfig.filter((obj) => obj!= null).map((item, index) => (
                                                            <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText></FormHelperText>
                                                </FormControl>)}
                                        </form>
                                    </ItemGrid>
                                </Grid>
                            </ItemGrid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.close() } >
                            Close
                        </Button>

                        <Button onClick={this.handleClose} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


ConfigContainerChart.defaultProps = {
    open: false,
    newChart:false
};

ConfigContainerChart.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    chartId: PropTypes.string,
    classes: PropTypes.object.isRequired,
    model: PropTypes.string,
    tab: PropTypes.number,
    data: PropTypes.object,
    newChart: PropTypes.bool
};

export default withStyles(styles)(ConfigContainerChart);






