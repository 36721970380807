import React from 'react';
import ReactDOM from 'react-dom';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import indexRoutes from 'routes/index.jsx';

import { Provider } from 'react-redux';
import store from './redux/store';

import 'assets/css/material-dashboard-react.css?v=1.2.0';


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                {indexRoutes.map((prop, index) => {
                    if (prop.type === 'private') {
                        return <PrivateRoute path={prop.path} component={prop.component} key={index} />;
                    } 
                    return <Route path={prop.path} component={prop.component} key={index} />;
                })}
            </Switch>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
