import { DATE, LIVE } from '../constants/action-types';

function changeDate(dates) {
    return { type: DATE, dates };
}

function changeLive(live) {
    return { type: LIVE, live };
}

export { changeDate, changeLive };