import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import consts from '../../const';
import { loading, login, loginFailed } from '../../redux/actions/auth';
import MessageSnackbar from '../../components/MessageSnackbar/MessageSnackbar';
import { Redirect } from 'react-router-dom';
import {
    TextField,
    CardActions,
    Button,
    CircularProgress,
} from '@material-ui/core';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
} from '@material-ui/core';

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
        justifyContent: 'flex-start',
    },
    card: {
        minWidth: 300,
        marginTop: '1em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    field: { width: '100%' },
    actions: {
        padding: '0 1em 1em 1em',
    },
    forgot: {
        fontSize: '0.9em'
    },
    register: {
        marginTop: 10,
        paddingTop: 10
    }
});

class SetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            password: '',
            password2: '',
            // usernameError: false,
            // passwordError: false,
            isAuthenticated: false,
            error: false
        };
        console.log(props);

        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    login() {

        this.props.loading();

        if(this.state.password != this.state.password2) {
            this.setState({
                error: true
            });
            this.props.loginFailed('Paswords not Match');
            return;
        }

        if (this.state.password && this.state.password2) {
            let credentials = { new_password1: this.state.password, new_password2: this.state.password2,
                uidb64:this.props.match.params.uid, token:this.props.match.params.token };

            axios.post(`${consts.API_BASE_URL}/api/auth/password_reset/`, credentials)
                .then(res => {
                    this.setState({open:true});
                })
                .catch(error => {
                    if (error) {
                        this.setState({
                            error: true
                        });

                        if (error.response) {
                            if (error.response.status < 500) {
                                if(error.response.data.non_field_errors){
                                    this.props.loginFailed(error.response.data.non_field_errors[0]);
                                }
                            } else {
                                this.props.loginFailed(`${error.response.status} - ${error.response.statusText}`);
                            }
                        } else {
                            this.props.loginFailed(`${error}`);
                        }
                    }
                });
        } else {
            this.setState({
                error: true
            });
            this.props.loginFailed('All fieds are required');
        }
    }


    handleChange(event) {

        // let error = this.state[event.target.id] === '' ? false : true;

        this.setState({
            [event.target.id]: event.target.value,
            //[`${event.target.id}Error`]: error
        });
    }


    componentDidMount() {
        if (this.props.token) {
            this.props.history.push('/dashboard');
        }
    }

    componentDidUpdate() {
        const isAuthenticated = this.props.isAuthenticated;
        if (isAuthenticated) {
            this.setState({
                isAuthenticated: true
            });
        }
    }

    handleClose = () => {
        this.setState({
            error: false
        });
    }

    render() {
        const { classes, isLoading } = this.props;

        return (
            <div>{
                (this.state.isAuthenticated) ?
                    <Redirect to='/dashboard' /> :
                    <div className={classes.main}>
                        <div className={classes.hint}></div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                 <TextField
                                        id="password"
                                        label="New Password"
                                        required
                                        error={this.state.passwordError}
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        className={classes.field}
                                        margin="normal"
                                        type="password"
                                    />
                            </div>

                            <div className={classes.input}>
                                 <TextField
                                        id="password2"
                                        label="Repeat Password"
                                        required
                                        error={this.state.passwordError2}
                                        value={this.state.password2}
                                        onChange={this.handleChange}
                                        className={classes.field}
                                        margin="normal"
                                        type="password"
                                    />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={isLoading}
                                className={classes.button}
                                fullWidth
                                onClick={this.login}

                            >
                                {isLoading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                Send
                            </Button>
                        </CardActions>
                        <Dialog
                            open={this.state.open}
                            aria-labelledby="max-width-dialog-title"
                        >
                                <DialogTitle id="max-width-dialog-title">Password recovery</DialogTitle>
                                <DialogContent>
                                <p>You sucessfully changed your password.</p>
                                            
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.props.history.push('/')} color="primary">
                                        Return to Login
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        <MessageSnackbar
                            open={this.state.error}
                            style={'warning'}
                            msg={this.props.errors}
                            close={this.handleClose}
                        />
                    </div>
            }
            </div>
        );
    }
}

SetPassword.propTypes = {
    classes: PropTypes.object,
    previousRoute: PropTypes.string,

    loading: PropTypes.func,
    login: PropTypes.func,
    loginFailed: PropTypes.func,

    isLoading: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    token: PropTypes.string,
    errors: PropTypes.string,

    history: PropTypes.object

};

function mapStateToProps(state) {
    return {
        isLoading: state.auth.isLoading,
        isAuthenticated: state.auth.isAuthenticated,
        token: state.auth.token,
        errors: state.auth.errors
    };
}

const mapDispatchToProps = {
    loading,
    login,
    loginFailed
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SetPassword));