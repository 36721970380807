import React from 'react';
import axios from 'axios';
import {
    Grid,
    TableRow,
    TableCell,
    FormGroup,
    FormControlLabel,
    Checkbox,
    withStyles,
} from '@material-ui/core';
import {
    Loading,
    RegularCard,
    Calendar,
    Table,
    ItemGrid,
    Button,
    CategoryFilterButton,
    MessageSnackbar,
} from 'components';
import consts from 'const';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { parseDecimals } from 'utils';
import reportsStyle from 'assets/jss/material-dashboard-react/reportsStyle';

let months = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];
let tableHead = [
    { column: 'name', label: 'Name' },
    { column: 'average0', label: 'Average Sunday' },
    { column: 'average1', label: 'Average Monday' },
    { column: 'average2', label: 'Average Tuesday' },
    { column: 'average3', label: 'Average Wednesday' },
    { column: 'average4', label: 'Average Thursday' },
    { column: 'average5', label: 'Average Friday' },
    { column: 'average6', label: 'Average Saturday' },
    { column: 'averageTotal', label: 'Average Total' },
];

class AverageProduct extends React.Component {
  state = {
      loading: true,
      message: false,
      onMessage: {
          style: '',
          msg: '',
      },
      categories: [],
      categoriesHidden: [],
      products: [],
      data: [],
      checkedSunday: true,
      checkedMonday: true,
      checkedTuesday: true,
      checkedWednesday: true,
      checkedThursday: true,
      checkedFriday: true,
      checkedSaturday: true,
      showItemsSold: false,
  };
  handleChange = (name) => (event) => {
      this.setState({ [name]: event.target.checked },()=>{
        if (name == 'showItemsSold'){
            this.postConfigDashboard();
        }
    });
  };

  getTableHead() {
      let data = JSON.parse(JSON.stringify(tableHead));
      if (this.state.checkedSunday === false) {
          data.splice(
              data.findIndex((obj) => obj.column === 'average0'),
              1
          );
      }
      if (this.state.checkedMonday === false) {
          data.splice(
              data.findIndex((obj) => obj.column === 'average1'),
              1
          );
      }
      if (this.state.checkedTuesday === false) {
          data.splice(
              data.findIndex((obj) => obj.column === 'average2'),
              1
          );
      }
      if (this.state.checkedWednesday === false) {
          data.splice(
              data.findIndex((obj) => obj.column === 'average3'),
              1
          );
      }
      if (this.state.checkedThursday === false) {
          data.splice(
              data.findIndex((obj) => obj.column === 'average4'),
              1
          );
      }
      if (this.state.checkedFriday === false) {
          data.splice(
              data.findIndex((obj) => obj.column === 'average5'),
              1
          );
      }
      if (this.state.checkedSaturday === false) {
          data.splice(
              data.findIndex((obj) => obj.column === 'average6'),
              1
          );
      }

      return data;
  }
  fetchData() {
      let dates = {};
      if (this.props.startDate && this.props.endDate) {
          dates = {
              startDate: this.props.startDate.format(),
              endDate: this.props.endDate.format(),
          };
      }

      let headers = {
          Authorization: `JWT ${localStorage.getItem('token')}`,
      };

      axios
          .get(consts.API_BASE_URL + '/api/products/average/day/', {
              params: dates,
              headers,
          })
          .then(({ data }) => {
              this.setState({
                  loading: false,
                  products: data,
              });
          })
          .catch((error) => {
              this.setState({
                  loading: false,
                  message: true,
                  onMessage: {
                      style: 'warning',
                      msg: 'Loading errorr, please try again.',
                  },
              });
          });
  }
  componentDidMount() {
      this.getConfig()
      let headers = {
          Authorization: `JWT ${localStorage.getItem('token')}`,
      };
      let dates = {};
      if (this.props.startDate && this.props.endDate) {
          dates = {
              startDate: this.props.startDate.format(),
              endDate: this.props.endDate.format(),
          };
      }
      axios
          .all([
              axios.get(consts.API_BASE_URL + '/api/categories/', { headers }),
              axios.get(consts.API_BASE_URL + '/api/products/average/day/', {
                  params: dates,
                  headers,
              }),
          ])
          .then(([categories, products]) => {
              this.setState({
                  categories: categories.data,
                  products: products.data,
                  loading: false,
              });
          })
          .catch((error) => {
              this.setState({
                  loading: false,
                  message: true,
                  onMessage: {
                      style: 'warning',
                      msg: 'Loading errorr, please try again.',
                  },
              });
          });
      document.title = 'Average Product';
  }

getConfig() {
    if (localStorage.getItem('showItemsSoldAverage') != 'undefined'){
        let showItemsSold= JSON.parse(localStorage.getItem('showItemsSoldAverage'));
        this.setState({showItemsSold,});
    }
    if (JSON.parse(localStorage.getItem('categoriesHiddenAverage'))){
        console.log('entrando')
        let categoriesHidden= JSON.parse(localStorage.getItem('categoriesHiddenAverage'));
        this.setState({categoriesHidden,});
    }
}


postConfigDashboard() {
    localStorage.setItem('showItemsSoldAverage', JSON.stringify(this.state.showItemsSold));    
    localStorage.setItem('categoriesHiddenAverage', JSON.stringify(this.state.categoriesHidden));
}





  savePdf() {
      var doc = new jsPDF('l', 'pt');
      let products = this.state.products;

      let start =
      this.props.startDate === null
          ? 'Start'
          : this.props.startDate.format('YYYY-MM-DD');
      let end =
      this.props.endDate === null
          ? 'End'
          : this.props.endDate.format('YYYY-MM-DD');
      doc.text(`Average Product - L'Artisane - ${start} - ${end}`, 80, 200);
      doc.addPage();

      const { categoriesHidden } = this.state;
      let index = 0;
      var dates_to_column = { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8 };
      months.forEach((obj, index) => {
          if (this.state['checked' + obj] === false) {
              delete dates_to_column[index + 1];
          }
      });
      Object.keys(dates_to_column).forEach((obj, index) => {
          dates_to_column[obj] = index + 1;
      });
      let total_column = dates_to_column[8];

      this.state.data.forEach((category) => {
          if (categoriesHidden.indexOf(category.id) < 0) {
              var head = this.getTableHead();
              var columns = head.map((obj) => obj.label);
              let total = ['Total:', 0, 0, 0, 0, 0, 0, 0, 0];
              var rows = category.products.map((product) => {
                  let data = [product.name, 0, 0, 0, 0, 0, 0, 0, 0];
                  products.data.forEach((obj) => {
                      if (obj.product_id === product.id) {
                          let column = dates_to_column[obj.date_part + 1];
                          if (column !== undefined) {
                              data[column] = obj.avg;
                              total[column] += obj.avg;
                              data[total_column] += obj.avg;
                              total[total_column] += obj.avg;
                          }
                      }
                  });
                  return data;
              });
              rows.push(total);

              rows = rows.map((row) => {
                  return row.map((obj) => {
                      if (typeof obj === 'number') {
                          obj = obj.toFixed(2);
                      }
                      return obj;
                  });
              });

              doc.setFontSize(18);
              doc.text(
                  category.name,
                  40,
                  index === 0 ? 40 : doc.autoTable.previous.finalY + 20
              );
              doc.setFontSize(12);
              doc.autoTable(columns, rows, {
                  startY: index === 0 ? 55 : doc.autoTable.previous.finalY + 30,
                  bodyStyles: { fontSize: 7 },
                  headerStyles: { fontSize: 9, fillColor: false, textColor: '#9c27b0' },
              });
              index += 1;
          }
      });

      doc.save('AverageProduct.pdf');
  }
  componentDidUpdate(prevProps, prevState) {
      if (
          prevProps.endDate !== this.props.endDate ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.groupBy !== this.props.groupBy ||
      prevProps.optionPerDay !== this.props.optionPerDay ||
      prevProps.timeSelect !== this.props.timeSelect
      ) {
          this.setState({ loading: true });
          this.fetchData();
      }
      if (prevState.categories !== this.state.categories) {
          let data = JSON.parse(JSON.stringify(this.state.categories));
          data.forEach((category) => {
              category.products = this.getProducts(category);
          });

          this.setState({ data: data });
      } else if (prevState.products !== this.state.products) {
          let data = JSON.parse(JSON.stringify(this.state.categories));
          data.forEach((category) => {
              category.products = this.getProducts(category);
          });

          this.setState({ data: data });
      }
  }
  getProducts(category) {
      let products = this.state.products;
      let dayTotal = {
          name: 'Total',
          average0: 0,
          average1: 0,
          average2: 0,
          average3: 0,
          average4: 0,
          average5: 0,
          average6: 0,
          averageTotal: 0,
      };
      products = category.products.map((product) => {
          products.data.forEach((obj) => {
              if (obj.product_id === product.id) {
                  product['average' + obj.date_part] = obj.avg;
                  dayTotal['average' + obj.date_part] += obj.avg;
              }
          });
          return product;
      });
      products.forEach((obj) => {
      //dayTotal["averageTotal"] += total;
      });
      category.totals = dayTotal;
      return products;
  }
  sumAverages = (obj) => {
      let total = 0;
      if (this.state.checkedSunday === true) total += obj['average0'] || 0;
      if (this.state.checkedMonday === true) {
          total += obj['average1'] || 0;
      }
      if (this.state.checkedTuesday === true) {
          total += obj['average2'] || 0;
      }
      if (this.state.checkedWednesday === true) {
          total += obj['average3'] || 0;
      }
      if (this.state.checkedThursday === true) {
          total += obj['average4'] || 0;
      }
      if (this.state.checkedFriday === true) {
          total += obj['average5'] || 0;
      }
      if (this.state.checkedSaturday === true) {
          total += obj['average6'] || 0;
      }

      return total;
  };

  render() {
      const { categoriesHidden, showItemsSold } = this.state;
      return (
          <div>
              <Loading open={this.state.loading} />
              <MessageSnackbar
                  open={this.state.message}
                  style={this.state.onMessage.style}
                  msg={this.state.onMessage.msg}
                  close={() => this.setState({ message: false })}
              />
              <Grid container>
                  <ItemGrid xs={12} sm={6} md={9}>
                      <RegularCard
                          content={
                              <div>
                                  <Calendar
                                      startDate={this.props.startDate} // momentPropTypes.momentObj or null,
                                      endDate={this.props.endDate}
                                      showPerDay={false}
                                  />
                                  <FormGroup row>
                                      <FormControlLabel
                                          control={
                                              <Checkbox
                                                  checked={this.state.checkedSunday}
                                                  onChange={this.handleChange('checkedSunday')}
                                              />
                                          }
                                          label="Sunday"
                                      />
                                      <FormControlLabel
                                          control={
                                              <Checkbox
                                                  checked={this.state.checkedMonday}
                                                  onChange={this.handleChange('checkedMonday')}
                                              />
                                          }
                                          label="Monday"
                                      />
                                      <FormControlLabel
                                          control={
                                              <Checkbox
                                                  checked={this.state.checkedTuesday}
                                                  onChange={this.handleChange('checkedTuesday')}
                                              />
                                          }
                                          label="Tuesday"
                                      />
                                      <FormControlLabel
                                          control={
                                              <Checkbox
                                                  checked={this.state.checkedWednesday}
                                                  onChange={this.handleChange('checkedWednesday')}
                                              />
                                          }
                                          label="Wednesday"
                                      />
                                      <FormControlLabel
                                          control={
                                              <Checkbox
                                                  checked={this.state.checkedThursday}
                                                  onChange={this.handleChange('checkedThursday')}
                                              />
                                          }
                                          label="Thursday"
                                      />
                                      <FormControlLabel
                                          control={
                                              <Checkbox
                                                  checked={this.state.checkedFriday}
                                                  onChange={this.handleChange('checkedFriday')}
                                              />
                                          }
                                          label="Friday"
                                      />
                                      <FormControlLabel
                                          control={
                                              <Checkbox
                                                  checked={this.state.checkedSaturday}
                                                  onChange={this.handleChange('checkedSaturday')}
                                              />
                                          }
                                          label="Saturday"
                                      />
                                  </FormGroup>
                              </div>
                          }
                      />
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} md={3}>
                      <RegularCard
                          content={
                              <div>
                                  <Button
                                      onClick={() => this.savePdf()}
                                      style={{ padding: '0 8px', margin: 0, marginRight: '6px' }}
                                      color="success"
                                  >
                    Download Report
                                  </Button>
                                  <CategoryFilterButton
                                      categoriesHidden = {this.state.categoriesHidden}
                                      categories={this.state.data}
                                      onCategorySelected={(categories) =>
                                        this.setState({categoriesHidden: categories},()=>{
                                            this.postConfigDashboard();
                                        })
                                      }
                                  />
                                  <FormControlLabel
                                      control={
                                          <Checkbox
                                              checked={this.state.showItemsSold}
                                              onChange={this.handleChange('showItemsSold')}
                                          />
                                      }
                                      label={'Show only items sold '}
                                  />
                              </div>
                          }
                      />
                  </ItemGrid>
              </Grid>

              <div id="contentPrint">
                  {this.state.data.map((obj) => {
                      if (categoriesHidden.indexOf(obj.id) < 0)
                          return (
                              <RegularCard
                                  key={obj.id}
                                  cardTitle={obj.name}
                                  content={
                                      <Table
                                          tableHeaderColor="primary"
                                          tablehead={this.getTableHead()}
                                          tabledata={obj.products}
                                          tablerowrender={(product, key) => {
                                              if ((showItemsSold === false&&this.sumAverages(product) === 0) || this.sumAverages(product) > 0)
                                                  return (
                                                      <TableRow key={key}>
                                                          <TableCell>{product.name}</TableCell>
                                                          {this.state.checkedSunday ? (
                                                              <TableCell numeric>
                                                                  {parseDecimals(product.average0)}
                                                              </TableCell>
                                                          ) : null}
                                                          {this.state.checkedMonday ? (
                                                              <TableCell numeric>
                                                                  {parseDecimals(product.average1)}
                                                              </TableCell>
                                                          ) : null}
                                                          {this.state.checkedTuesday ? (
                                                              <TableCell numeric>
                                                                  {parseDecimals(product.average2)}
                                                              </TableCell>
                                                          ) : null}
                                                          {this.state.checkedWednesday ? (
                                                              <TableCell numeric>
                                                                  {parseDecimals(product.average3)}
                                                              </TableCell>
                                                          ) : null}
                                                          {this.state.checkedThursday ? (
                                                              <TableCell numeric>
                                                                  {parseDecimals(product.average4)}
                                                              </TableCell>
                                                          ) : null}
                                                          {this.state.checkedFriday ? (
                                                              <TableCell numeric>
                                                                  {parseDecimals(product.average5)}
                                                              </TableCell>
                                                          ) : null}
                                                          {this.state.checkedSaturday ? (
                                                              <TableCell numeric>
                                                                  {parseDecimals(product.average6)}
                                                              </TableCell>
                                                          ) : null}
                                                          <TableCell numeric>
                                                              {parseDecimals(this.sumAverages(product))}
                                                          </TableCell>
                                                      </TableRow>
                                                  );
                                          }}
                                          tablefooter={
                                              <TableRow>
                                                  <TableCell>Total</TableCell>
                                                  {this.state.checkedSunday ? (
                                                      <TableCell numeric>
                                                          {parseDecimals(obj.totals.average0)}
                                                      </TableCell>
                                                  ) : null}
                                                  {this.state.checkedMonday ? (
                                                      <TableCell numeric>
                                                          {parseDecimals(obj.totals.average1)}
                                                      </TableCell>
                                                  ) : null}
                                                  {this.state.checkedTuesday ? (
                                                      <TableCell numeric>
                                                          {parseDecimals(obj.totals.average2)}
                                                      </TableCell>
                                                  ) : null}
                                                  {this.state.checkedWednesday ? (
                                                      <TableCell numeric>
                                                          {parseDecimals(obj.totals.average3)}
                                                      </TableCell>
                                                  ) : null}
                                                  {this.state.checkedThursday ? (
                                                      <TableCell numeric>
                                                          {parseDecimals(obj.totals.average4)}
                                                      </TableCell>
                                                  ) : null}
                                                  {this.state.checkedFriday ? (
                                                      <TableCell numeric>
                                                          {parseDecimals(obj.totals.average5)}
                                                      </TableCell>
                                                  ) : null}
                                                  {this.state.checkedSaturday ? (
                                                      <TableCell numeric>
                                                          {parseDecimals(obj.totals.average6)}
                                                      </TableCell>
                                                  ) : null}
                                                  <TableCell numeric>
                                                      {parseDecimals(this.sumAverages(obj.totals))}
                                                  </TableCell>
                                              </TableRow>
                                          }
                                      />
                                  }
                              />
                          );
                      else return null;
                  })}
              </div>
          </div>
      );
  }
}

AverageProduct.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        startDate: state.calendar.startDate,
        endDate: state.calendar.endDate,
        holiday: state.calendar.holiday,
        optionPerDay: state.calendar.optionPerDay,
        groupBy: state.calendar.groupBy,
        timeSelect: state.calendar.timeSelect,
        live: state.calendar.live,
    };
}

export default connect(mapStateToProps)(
    withStyles(reportsStyle)(AverageProduct)
);
