// ##############################
// // // IconButton styles
// #############################

import {} from "assets/jss/material-dashboard-react.jsx";

const categoryfilterButtonStyle = {
  button: {

  },

};

export default categoryfilterButtonStyle;
