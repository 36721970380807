import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import cx from 'classnames';

import buttonStyle from 'assets/jss/material-dashboard-react/buttonStyle';

function SmallButton({ ...props }) {
    const {
        classes,
        color,
        round,
        children,
        fullWidth,
        disabled,
        small,
        noradius,
        ...rest
    } = props;
    const btnClasses = cx({
        [classes[color]]: color,
        [classes.round]: round,
        [classes.fullWidth]: fullWidth,
        [classes.small]: small,
        [classes.noradius]: noradius
    });
    return (
        <Button {...rest} className={classes.button + ' ' + btnClasses}>
            {children}
        </Button>
    );
}

SmallButton.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        'primary',
        'primaryDark',
        'info',
        'success',
        'warning',
        'danger',
        'rose',
        'white',
        'gray',
        'simple',
        'transparent'
    ]),
    round: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    noradius: PropTypes.bool
};

export default withStyles(buttonStyle)(SmallButton);
