import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { ReceiptOutlined} from '@material-ui/icons';

import { SettingsCard, ItemGrid } from 'components';
import settingsStyle from 'assets/jss/material-dashboard-react/settingsStyle';

function ReportsView({ ...props }) {
    return (
        <Grid container>
            <ItemGrid xs={12} sm={12} md={3}>
                <SettingsCard
                    cardTitle={'Summary Sales'}
                    icon={ReceiptOutlined}
                    to={'/summarysales'}
                />

            </ItemGrid>
            <ItemGrid xs={12} sm={12} md={3}>
                <SettingsCard
                    cardTitle={'Average Product'}
                    icon={ReceiptOutlined}
                    to={'/averageproduct'}
                />
            </ItemGrid>

        </Grid>
    );
}

export default withStyles(settingsStyle)(ReportsView);
