import React from "react";
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableSortLabel,
  TableFooter
} from "@material-ui/core";

import PropTypes from "prop-types";

import tableStyle from "assets/jss/material-dashboard-react/tableStyle";

function sortColumn(data, orderBy, order){
    return order === 'desc'
      ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
      : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
}

class CustomTable extends React.Component {
  state = {
    orderBy: "",
    order:"",
    data:[]
  };
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state.orderBy = props.orderBy;
    this.state.order = props.order;
    this.state.data = sortColumn(props.tabledata, this.state.orderBy, this.state.order);
    //this.handleClick = this.handleClick.bind(this);
  }
  createSortHandler = property => event => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    const data = sortColumn(this.state.data, orderBy, order);
    this.setState({ data, order, orderBy });
  };

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.tabledata !== this.props.tabledata){
      this.setState({data:this.props.tabledata})
    }
  }
  render(){
    const { classes, tablehead, tableheadercolor, tablerowrender } = this.props;
    const { orderBy, order, data} = this.state;
    return (
      <div className={classes.tableResponsive}>
        <Table className={classes.table}>
          {tablehead !== undefined ? (
            <TableHead className={classes[tableheadercolor + "TableHeader"]}>
              <TableRow>
                {tablehead.map((prop, key) => {
                  return (
                    <TableCell
                      className={classes.tableCell + " " + classes.tableheadCell}
                      key={key}
                      sortDirection={orderBy === prop.column ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === prop.column}
                        direction={order}
                        onClick={this.createSortHandler(prop.column)}
                      >
                        {prop.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {data.map((obj, key) => tablerowrender(obj, key))}
          </TableBody>
            {this.props.tablefooter &&
            <TableFooter>
                {this.props.tablefooter}
            </TableFooter>
            }
        </Table>
      </div>
    );
  }
}

CustomTable.defaultProps = {
  tableheadercolor: "gray",
  orderBy:"name",
  order:"asc"
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableheadercolor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tablehead: PropTypes.arrayOf(PropTypes.object),
  tabledata: PropTypes.arrayOf(PropTypes.object),
  tablerowrender: PropTypes.func,
  tablefooter: PropTypes.node,
  orderBy: PropTypes.string,
  order: PropTypes.string,

};

export default withStyles(tableStyle)(CustomTable);
