import {
    USER_LOADING,
    USER_LOADED,
    AUTHENTICATION_ERROR,
    LOGIN_SUCCESSFUL,
    LOGOUT_SUCCESSFUL,
    LOGIN_FAILED,
    REFRESH_SUCCESSFUL, 
    LOGOUT_TIMEOUT
} from '../constants/action-types';
import axios from 'axios';


function loading(action) {
    return { type: USER_LOADING, action };
}

function loaded(auth) {
    return { type: USER_LOADED, auth };
}

function error(action) {
    return { type: AUTHENTICATION_ERROR, action };
}

function login(token, remenber) {

    return { type: LOGIN_SUCCESSFUL, token, remenber };
}

function refresh(token) {
    return { type: REFRESH_SUCCESSFUL, token };
}

function logoutTimeout(){
    return { type: LOGOUT_TIMEOUT };
}
function logout(action) {
    return { type: LOGOUT_SUCCESSFUL, action };
}

function loginFailed(error) {
    return { type: LOGIN_FAILED, error };
}

function saveInitial(devices, employees) {
    return {
      type: 'SAVE_INITIAL',
      devices, employees
    };
}
  
function fetchDataInitial(){
    return (dispatch, getState, api) => {
        let headers = {
            Authorization: `JWT ${localStorage.getItem('token')}`
        };
        axios.all([
            api.get("/devices/", {headers}),
            api.get("/employees/", {headers}),
        ]).then(axios.spread((dataDevice, dataEmployee) => {
            console.log(dataDevice);
            dispatch(saveInitial(dataDevice.data, dataEmployee.data))
        }));
    }
}
export { loading, loaded, login, logout, error, loginFailed, refresh, logoutTimeout, fetchDataInitial };