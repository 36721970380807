/** Calendar */
export const DATE = 'DATE';
export const LIVE = 'LIVE';

/** Auth */
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const REFRESH_SUCCESSFUL = 'REFRESH_SUCCESSFUL';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_TIMEOUT = 'LOGOUT_TIMEOUT';
