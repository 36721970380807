const initialState = {
    token: localStorage.getItem('token') || null,
    isAuthenticated: false,
    isLoading: false,
    user: false,
    remenber: false,
    timeout:false,
    errors: '',
};


export default function auth(state = initialState, action) {

    switch (action.type) {

    case 'USER_LOADING':
        return { ...state, isLoading: true };

    case 'USER_LOADED':
        return { ...state, 
            isAuthenticated: action.auth, 
            isLoading: false, 
        };

    case 'LOGIN_SUCCESSFUL':
        localStorage.setItem('token', action.token);
        return { 
            ...state, 
            remenber: action.remenber,
            isAuthenticated: true, 
            isLoading: false, 
            errors: null 
        };
    case 'REFRESH_SUCCESSFUL':
        localStorage.setItem('token', action.token);
        return {...state};

    case 'LOGOUT_TIMEOUT':
        return {...state, timeout:true};

    case 'AUTHENTICATION_ERROR':
    case 'LOGIN_FAILED':
        return { 
            ...state, 
            isAuthenticated: false, 
            isLoading: false, 
            errors: action.error
        };
    case 'LOGOUT_SUCCESSFUL':
        localStorage.removeItem('token');
        return {
            ...state, token: null, user: null,
            isAuthenticated: false, isLoading: false
        };

    default:
        return state;
    }
}