import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/index';
import { composeWithDevTools } from 'redux-devtools-extension';
import axios from 'axios';
import consts from '../const';
import thunk from 'redux-thunk';


const client = axios.create({ //all axios can be used, shown in axios documentation
    baseURL:consts.API_BASE_URL + '/api',
    responseType: 'json'
  });

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(
        thunk.withExtraArgument(client), 
    ))
);

export default store;