import React from "react";
import { withStyles, Grid } from "@material-ui/core";

import {
  ShowChart, Store, SupervisorAccount
} from "@material-ui/icons";

import {
  SettingsCard, ItemGrid
} from "components";

import settingsStyle from "assets/jss/material-dashboard-react/settingsStyle";

function SettingsView({ ...props }) {
  return (
    <Grid container>
        <ItemGrid xs={12} sm={12} md={3}>
          <SettingsCard
            cardTitle={"Stores"}
            icon={Store}
            to={"/stores"}
          />

        </ItemGrid>
        <ItemGrid xs={12} sm={12} md={3}>
          <SettingsCard
            cardTitle={"Users"}
            icon={SupervisorAccount}
            to={"/users"}
          />
        </ItemGrid>
        <ItemGrid xs={12} sm={12} md={3}>
          <SettingsCard
            cardTitle={"Employees"}
            icon={SupervisorAccount}
            to={"/employees"}
          />
        </ItemGrid>
        <ItemGrid xs={12} sm={12} md={3}>
          <SettingsCard
            cardTitle={"Graphs"}
            icon={ShowChart}
            to={"/graphs"}
          />
        </ItemGrid>
    </Grid>
  );
}

export default withStyles(settingsStyle)(SettingsView);
