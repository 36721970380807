import DashboardPage from 'views/Dashboard/Dashboard.jsx';
import Trends from 'views/Trends/Trends.js';
import ReportsView from 'views/Reports/ReportsView.jsx';
import AverageProduct from 'views/Reports/AverageProduct.jsx';
import SummarySales from 'views/Reports/SummarySales.jsx';
import SettingsView from 'views/Settings/SettingsView.js';
import GraphsForm from 'views/Settings/GraphsForm.js';
import UsersList from 'views/Users/UsersList.js';
import EmployeesList from 'views/Employees/EmployeesList.jsx';
import StoresList from 'views/Stores/StoresList.js';

import {
    Dashboard,
    ReceiptOutlined,
    ShowChart,
    Settings,
} from '@material-ui/icons';

const dashboardRoutes = [
    {
        path: '/dashboard',
        sidebarName: 'Dashboard',
        navbarName: 'Dashboard',
        icon: Dashboard,
        component: DashboardPage
    },
    {
        path: '/trends',
        sidebarName: 'Trends',
        navbarName: 'Trends',
        icon: ShowChart,
        component: Trends
    },
    {
        path: '/reports',
        sidebarName: 'Reports',
        navbarName: 'Reports',
        icon: ReceiptOutlined,
        component: ReportsView
    },{
        path: '/averageproduct',
        navbarName: 'Reports Average',
        component: AverageProduct,
        visible:false,
    },
    {
        path: '/summarysales',
        navbarName: 'Summary Sales',
        component: SummarySales,
        visible:false,
    },
    {
        path: '/settings',
        sidebarName: 'Settings',
        navbarName: 'Settings',
        icon: Settings,
        component: SettingsView
    },
    {
        visible:false,
        path: '/users',
        navbarName: 'Users',
        component: UsersList
    },
    {
        visible:false,
        path: '/employees',
        navbarName: 'Employees',
        component: EmployeesList
    },
    {
        visible:false,
        path: '/stores',
        navbarName: 'Stores',
        component: StoresList
    },
    {
        visible:false,
        path: '/graphs',
        navbarName: 'Graphs Settings',
        component: GraphsForm
    },
    { redirect: true, path: '/', to: '/dashboard', navbarName: 'Redirect' }
];

export default dashboardRoutes;
