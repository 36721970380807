import axios from 'axios'
import { localStorageService } from '../services'

export default (args, validateErrors=true) => {
  const config = {}
  const authToken = localStorageService.read('token')

  config.headers = {}
  if (authToken) {
    config.headers['Authorization'] = 'JWT ' + authToken
  }
  if ('headers' in args) {
    for (let key in args.headers) {
      config.headers[key] = args.headers[key]
    }
  }

  config.url = args.url
  config.method = args.method

  if ('responseType' in args) {
    config.responseType = args.responseType
  }

  if ('body' in args) {
    config.data = args.body
  }

  localStorageService.update('latestApiRequestDate', new Date().getTime())
  return axios(config).catch(error => {
    if (validateErrors && error.message === 'Network Error') {
      localStorageService.delete('authToken')
      window.location.reload()
    }
  })
}
