import React from "react";
import {
  withStyles,
  Card,
  CardContent,
  CardActions,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";

import statsCardStyle from "assets/jss/material-dashboard-react/statsCardStyle";

function StatsCard({ ...props }) {
  const {
    classes,
    title,
    description,
    statLink,
    small,
    statText,
    statAlternativeText,
    statIconColor,
    styleCard
  } = props;
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent} style={styleCard}>
        <Typography component="p" className={classes.cardCategory}>
          {title}
        </Typography>
        <Typography
          variant="h2"
          className={classes.cardTitle}
        >
          {description}{" "}
          {small !== undefined ? (
            <small className={classes.cardTitleSmall}>{small}</small>
          ) : null}
        </Typography>
      </CardContent>
      {statText !== undefined ? (
      <CardActions className={classes.cardActions}>
        <div className={classes.cardStats}>
         {props.statIcon !== undefined ? (
          <props.statIcon
            className={
              classes.cardStatsIcon +
              " " +
              classes[statIconColor + "CardStatsIcon"]
            }
          />) : null}
             {" "}
          {statLink !== undefined ? (
            <a href={statLink.href} className={classes.cardStatsLink}>
              {statLink.text}
            </a>
          ) : statText !== undefined ? (
            statText
          ) : null}
          {statAlternativeText !== undefined &&
          <p className={classes.cardStatsAlternative}>
            {statAlternativeText}
          </p>
          }
        </div>
      </CardActions>
      ) : null}

    </Card>
  );
}

StatsCard.defaultProps = {
  iconColor: "purple",
  statIconColor: "gray"
};

StatsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  title: PropTypes.node,
  description: PropTypes.node,
  small: PropTypes.node,
  statIcon: PropTypes.object,
  styleCard: PropTypes.object,
  statIconColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  statLink: PropTypes.object,
  statText: PropTypes.node,
  statAlternativeText: PropTypes.node
};

export default withStyles(statsCardStyle)(StatsCard);
