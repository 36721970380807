import React from 'react';
import {
    withStyles,
    IconButton,
    Hidden,
    MenuItem,
    Paper,
    ClickAwayListener,
    MenuList,
    Grow,
    Button
} from '@material-ui/core';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { Person, Dashboard } from '@material-ui/icons';
import headerLinksStyle from 'assets/jss/material-dashboard-react/headerLinksStyle';
import PropTypes from 'prop-types';
import Poppers from '@material-ui/core/Popper';
import classNames from 'classnames';

const optionsUserMenu = [
    'logout'
];


class HeaderLinks extends React.Component {
    state = {
        open: false,
        anchorEl: null
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleClick = name => event => {
        this.setState({ [name]: event.currentTarget });
    };

    handleSetOption = (option) => {
        this.setState({
            anchorEl: null,
        });
        
        switch (option) {
        case 'logout':
            return this.props.logout();
        default:
            break;
        }
    };

    handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
    };

    render() {
        const { classes } = this.props;
        const { open } = this.state;
        return (
            <div>
                <IconButton
                    color="inherit"
                    aria-label="Dashboard"
                    className={classes.buttonLink}
                >
                    <Dashboard className={classes.links} />
                    <Hidden mdUp>
                        <p className={classes.linkText}>Dashboar</p>
                    </Hidden>
                </IconButton>
                <Button
                    buttonRef={node => {
                        this.anchorEl = node;
                    }}
                    color="inherit"
                    aria-owns={open ? 'menu-list-grow' : null}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                    className={classes.buttonLink}
                >
                    <Person className={classes.links} />
                    <Hidden mdUp>
                        <p className={classes.linkText}>Profile</p>
                    </Hidden>
                </Button>
                <Poppers
                    open={open}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal
                    className={
                        classNames({ [classes.popperClose]: !open }) +
                            ' ' +
                            classes.pooperNav
                    }
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{
                                transformOrigin:
                                        placement === 'bottom' ? 'left top' : 'left bottom'
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList role="menu">
                                        {optionsUserMenu.map(option => (
                                            <MenuItem key={option} onClick={() => this.handleSetOption(option)} disableRipple >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Poppers>
            </div>
        );
    }
}

HeaderLinks.propTypes = {
    classes: PropTypes.object,
    logout: PropTypes.func
};

function mapStateToProps() {
    return {
    };
}

const mapDispatchToProps = {
    logout
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(headerLinksStyle)(HeaderLinks));
