import React from "react";
import { Grid } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import {
  RegularCard,
  Button,
  CustomInput,
  ItemGrid
} from "components";
import axios from 'axios';
import consts from "const";

class GraphsForm  extends React.Component {
  state = {
    open: false,
    data:{
      numberProducts:20,
    }
  };
  handleChange = name => event => {
    this.setState({
      data:{[name]: event.target.value,}
    });
  };
  handleClick = () => {
    axios.patch(consts.API_BASE_URL + "/api/users/",{"number_products":this.state.data.numberProducts}).then(
        ({data}) => {
            this.setState({ open: true });
        }).catch(function (error) {
        console.log(error);
    });

  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  render(){
  return (
    <div>
      <Grid container>
        <ItemGrid xs={12} sm={12} md={8}>
          <RegularCard
            content={
              <div>
                    <CustomInput
                      labelText="Max number of products in the graphs"
                      id="number-products"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type:"number",
                        InputLabelProps:{
                          shrink: true,
                        },
                        defaultValue:this.state.data.numberProducts,
                        onChange:this.handleChange('name')
                      }}
                    />
              </div>
            }
            footer={<Button  onClick={this.handleClick} color="primary">Update Settings</Button>}
          />
        </ItemGrid>
      </Grid>
      <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Settings saved</span>}
        />
    </div>
  );
}
}

export default GraphsForm;
