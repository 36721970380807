import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import consts from '../../const';
import { loading, login, loginFailed } from '../../redux/actions/auth';
import MessageSnackbar from '../../components/MessageSnackbar/MessageSnackbar';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getUserMePromise,putUserMePromise } from '../../promises'

import {
    TextField,
    CardActions,
    Button,
    CircularProgress,
} from '@material-ui/core';

const styles = theme =>  ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
        justifyContent: 'flex-start',
    },
    card: {
        minWidth: 300,
        marginTop: '1em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    field: { width: '100%' },
    actions: {
        padding: '0 1em 1em 1em',
    },
    forgot: {
        fontSize: '0.9em'
    },
    register: {
        marginTop: 10,
        paddingTop: 10
    }
});

class LoginView extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            username: '',
            password: '',
            // usernameError: false,
            // passwordError: false,
            isAuthenticated: false,
            error: false,
            remenber: false
        };

        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    login(){

        this.props.loading();

        localStorage.setItem('rememberMe',this.state.remenber);
        localStorage.setItem('username', this.state.remenber ? this.state.username:'');
        

        if (this.state.username && this.state.password) {

            let credentials = { username: this.state.username, password: this.state.password, remenber:this.state.remenber };
    
            axios.post(`${consts.API_BASE_URL}/api/auth/`, credentials)
                .then(res => {
                    this.props.login(res.data.token, this.state.remenber);
                })
                .catch(error => {
                    if (error) {
                        this.setState({
                            error: true
                        });

                        if (error.response) {
                            if (error.response.status == 400) {
                                this.props.loginFailed(error.response.data.non_field_errors[0]);
                            } else {
                                this.props.loginFailed(`${error.response.status} - ${error.response.statusText}`);
                            }
                        } else {
                            this.props.loginFailed(`${error}`);
                        }             
                    }
                });
        } else {
            this.setState({
                error: true
            });
            this.props.loginFailed('all fieds are required');
        }
    }


    handleChange(event) {
        // let error = this.state[event.target.id] === '' ? false : true;
        this.setState({
            [event.target.id]: event.target.value,
            //[`${event.target.id}Error`]: error
        });
    }
    handleChangeCheckbox = name => event => {
        this.setState({ [name]: event.target.checked });
      }


    componentDidMount() {
         const remenber = localStorage.getItem('rememberMe') ==='true'
         const username = remenber ? localStorage.getItem('username'):''
         this.setState({remenber,username})

        if (this.props.token) {
            this.props.history.push('/dashboard');
        }
        if(this.props.timeout){
            this.props.loginFailed('Your session timeout.');
            this.setState({
                error: true
            });
        }
    }


    getConfig() {
        getUserMePromise().then(res => { debugger});
    }

    componentDidUpdate() {
        const isAuthenticated = this.props.isAuthenticated;
        if (isAuthenticated) {
            this.setState({
                isAuthenticated: true
            });
        }
        console.log(this.props);
    }
    
    handleClose = () => {
        this.setState({
            error: false
        });
    }

    render() {
        const { classes, isLoading } = this.props;
        return (
            <div>{
                (this.state.isAuthenticated) ? 
                    <Redirect to='/dashboard'/> :
                    <div className={classes.main}>
                            <div className={classes.hint}></div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <TextField
                                        id="username"
                                        label="Email"
                                        required
                                        error={this.state.usernameError}
                                        value={this.state.username}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        className={classes.field}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <TextField
                                        id="password"
                                        label="Password"
                                        required
                                        error={this.state.passwordError}
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        className={classes.field}
                                        margin="normal"
                                        type="password"
                                    />
                                     <FormControlLabel
                                        control={
                                            <Checkbox
                                            checked={this.state.remenber}
                                            onChange={this.handleChangeCheckbox('remenber')}
                                            value="Remenber me"
                                            color="primary"
                                            />
                                        }
                                        label="Remember me"
                                        style={{width:"100%"}}
                                        />
                                        <br />
                                    <Link to="/user/lost-password" className={classes.forgot}>
                                        Forgot you password ?
                                    </Link>
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={isLoading}
                                    className={classes.button}
                                    fullWidth
                                    onClick={this.login}

                                >
                                    {isLoading && (
                                        <CircularProgress size={25} thickness={2} />
                                    )}
                                    Login
                                </Button>
                            </CardActions>
                        <div className={classes.register}>
                            <Link to="/user/register" >
                                Create account
                            </Link>
                        </div>


                        <MessageSnackbar
                            open={this.state.error}
                            style={'warning'}
                            msg={this.props.errors}
                            close={this.handleClose}
                        />
                    </div>
            }
            </div>
        );
    }
}

LoginView.propTypes = {
    classes: PropTypes.object,
    previousRoute: PropTypes.string,

    loading: PropTypes.func,
    login: PropTypes.func,
    loginFailed: PropTypes.func,

    isLoading: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    token: PropTypes.string,
    errors: PropTypes.string,

    history: PropTypes.object

};

function mapStateToProps(state) {
    return {
        isLoading: state.auth.isLoading,
        isAuthenticated: state.auth.isAuthenticated,
        token: state.auth.token,
        errors: state.auth.errors,
        timeout: state.auth.timeout
    };
}

const mapDispatchToProps = {
    loading,
    login,
    loginFailed
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginView));