import React from 'react';
import { withStyles, CircularProgress, Dialog, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';

import loadingStyle from 'assets/jss/material-dashboard-react/loadingStyle';

class Loading extends React.Component {

    render(){
        const { classes, ...other } = this.props;

        return (
            <Dialog aria-labelledby="loading-title"
                disableBackdropClick
                disableEscapeKeyDown
                {...other}>
                <DialogTitle id="loading-title">Loading</DialogTitle>
                <div className={classes.container}>
                    <CircularProgress  />
                </div>
            </Dialog>
        );
    }
}

Loading.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool
};

export default withStyles(loadingStyle)(Loading);
