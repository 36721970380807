import React from "react";
import {
  withStyles,
  Card,
  CardContent
} from "@material-ui/core";
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink } from "react-router-dom";

import settingsCardStyle from "assets/jss/material-dashboard-react/settingsCardStyle";

function SettingsCard({ ...props }) {
  const {
    classes,
    plainCard,
    cardTitle
  } = props;
  const plainCardClasses = cx({
    [" " + classes.cardPlain]: plainCard
  });
  return (
    <NavLink
      to={props.to}
    >
    <Card className={classes.card + plainCardClasses}>
      <CardContent  className={classes.cardContent}>
      <props.icon className={classes.cardIcon} />
      <h5 className={classes.cardTitle}>{cardTitle}</h5>
      </CardContent>
    </Card>
    </NavLink>
  );
}

SettingsCard.propTypes = {
  plainCard: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  cardTitle: PropTypes.node,
  icon: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired
};

export default withStyles(settingsCardStyle)(SettingsCard);
