
const calendarStyle = {
  
  input:{
    backgroundColor:"white!important", 
    border:"1px solid #ccc",
    borderRadius:"10px",
    width:"6.2rem",
    overflow:"hidden",
    marginRight:"10px",
    "&::before": {
      borderBottom:"0 !important"
    },
    '& label': {
      fontSize:".8rem",
      transform:"translate(10px, 10px) scale(0.75) !important"
    },
    '& .MuiSelect-select': {
      fontSize:".8rem",
      lineHeight: "1rem",
      padding:"10px 10px 8px"
    },
  },
  icons:{
    backgroundColor:"white!important", 
    border:"1px solid #ccc",
    borderRadius:"10px",
    minWidth:"1.5rem",
    overflow:"hidden",
    marginRight:"10px",
    fontSize:"1.5rem",
    padding:"11px 10px"
  }
};
export default calendarStyle;
