export const typesOfGraphs = [{
    id: 'TotalSales',
    content: 'Total Sales',
    model: 'bar'
},
{
    id: 'Transactions',
    content: 'Transactions',
    model: 'bar'
},
{
    id: 'AverageTicket',
    content: 'Average Ticket',
    model: 'line'
},
{
    id: 'NetSalesByHour',
    content: 'Net Sales ByHour',
    model: 'bar'
},
{
    id: 'RevenuePerCategory',
    content: 'Revenue Per Category',
    model: 'markers'
},
{
    id: 'Top20RevenuePerItem',
    content: 'Top 20 Revenue PerItem',
    model: 'bar'
},
{
    id: 'Top20RevenuePerCategory',
    content: 'Top 20 Revenue Per Category',
    model: 'bar'
},
{
    id: 'Top20CategoriesSold',
    content: 'Top 20 Categories Sold',
    model: 'bar'
},
{
    id: 'SalesByEmployee',
    content: 'Sales By Employee',
    model: 'bar'
},
{
    id: 'Top20ItemsSold',
    content: 'Top 20 Items Sold',
    model: 'bar'
},
{
    id: 'ProductByHour',
    content: 'Products By Hour',
    model: 'bar'
}
];