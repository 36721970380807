
const loadingStyle = {
  container: {
    minWidth: "300px",
    height: "60px",
    textAlign:"center",
    margin:"10px"

  },
};

export default loadingStyle;
