import React from 'react';
import Plot from 'react-plotly.js';
import DialogTitle from '../Dialog/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import GridOn from '@material-ui/icons/GridOn';
import DeleteIcon from '@material-ui/icons/Delete';
import consts from '../../const';
import DialogContent from '@material-ui/core/DialogContent';
import Settings from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
    withStyles,
    Card,
    CardContent,
    Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import chartCardStyle from 'assets/jss/material-dashboard-react/chartCardStyle';

import Select  from 'react-select';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}



class ChartCard extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openTable: false,
            openConfig: false,
            openDetail: false,
            detailData:{},
            visible: true,
            editChart: true
        };
    }

    chartLayoutOptions = {
        autosize: true,
        margin: {
            l: 40,
            r: 40,
            b: 30,
            t: 20,
            pad: 0,
            autoexpand:true
        },
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
    };

    chartLayoutOptionsFullScreen = {
        autosize: true,
        legend: {'orientation': 'h'},
        margin: {
            autoexpand:true
        }
    };
    handleClickOpenSubMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
      handleClickCloseSubMenu = () => {
          this.setState({ anchorEl: null });
      };
    
    handleClickOpen = () => {
        this.setState({ open: true });
        if (this.props.isDrag) {
            this.props.isDrag(true);
        }
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.isDrag) {
            this.props.isDrag(false);
        }
    };

    handleClickOpenTable = () => {
        this.setState({ openTable: true, anchorEl: null });
    };

    handleClickOpenDetail = (event) => {
        if (this.props.handleDetailDialog) 
            this.props.handleDetailDialog(event);
    };
    handleClickDelete = () => {
        this.setState({ anchorEl: null });
        this.props.openConfig(
            'delete', 
            this.props.id, 
            this.props.model, 
            this.props.obj
        );
    };

    handleClickOpenConfig = () => {
        this.setState({ anchorEl: null });
        this.props.openConfig(
            'config', 
            this.props.id, 
            this.props.model, 
            this.props.obj
        );
    };

    handleCloseTable = () => {
        this.setState({ openTable: false });
    };

    toggleHoverState = () => {
        this.setState({
            visible: !this.state.visible,
        });
    };
    getSelectedId(){
        if(typeof this.props.selectedId == "string" && this.props.selectedId != "" && this.props.selectedId != undefined){
            let instance = this.props.selectData.find((obj) => obj.url == this.props.selectedId)
            if(instance)
                return {"value":instance.url, "label":instance.name}
        }
        console.log(this.props.selectedId);
        return this.props.selectedId;
    }

    render(){
        const {
            classes,
            title,
            text
        } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div>
                <Card className={classes.card}>
                    <CardContent className={'card-content ' + classes.cardContent} >
                        <Typography variant="h4" className={classes.cardTitle} >
                            {this.props.toolBox === true ? (
                                <div className="action-button">
                                    <IconButton
                                        style={{ float: 'right' }}
                                        aria-label="More"
                                        aria-owns={open ? 'long-menu' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleClickOpenSubMenu}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={this.handleClickCloseSubMenu}
                                    >
                                        <MenuItem key={'delete'} 
                                            onClick={this.handleClickDelete}>
                                            <ListItemIcon className={classes.icon}><DeleteIcon /></ListItemIcon>
                                            <ListItemText classes={{ primary: classes.primary }} inset primary="Delete" />
                                        </MenuItem>
                                        {this.props.haveConfigModal === true  ? (
                                            <MenuItem key={'settings'} 
                                                onClick={this.handleClickOpenConfig}>
                                                <ListItemIcon className={classes.icon}><Settings /></ListItemIcon>
                                                <ListItemText classes={{ primary: classes.primary }} inset primary="Settings" />
                                            </MenuItem>
                                        ) : null}

                                        {this.props.haveDialogTable === true  ? (
                                            <MenuItem key={'grid'} 
                                                onClick={this.handleClickOpenTable}>
                                                <ListItemIcon className={classes.icon}><GridOn /></ListItemIcon>
                                                <ListItemText classes={{ primary: classes.primary }} inset primary="Table" />
                                            </MenuItem>
                                        ) : null}
                                        
                                    </Menu>
                                    {this.props.haveEditMode === true ? (
                                        <IconButton style={{ float: 'right' }}
                                            color="inherit" onClick={this.props.showTable == false ? this.handleClickOpen : this.handleClickOpenTable } aria-label="Close">
                                            <FullscreenIcon />
                                        </IconButton>) : null}
                                    {this.props.haveVisibilityButton === true ? (
                                        <IconButton style={{ float: 'right' }}
                                            color="inherit" onClick={this.toggleHoverState} aria-label="Close">
                                            {this.state.visible ? (<Visibility />) : (<VisibilityOff />)}
                                        </IconButton>) : null}
                                        {this.props.selectData !== undefined && this.props.selectData !== null && (
                                            <div style={{width: '200px', float: 'right'}}>
                                            <Select 
                                            value={this.getSelectedId()} onChange={this.props.selectedChange} 
                                            options={this.props.selectData.map((obj) => ({value:obj.url, label:obj.name}))} />
                                        </div>
                                        )}  
                                    
                                </div>
                            ) : null}
                            {title}
                        </Typography>
                        <Typography component="p" className={classes.cardCategory}>
                            {text}
                        </Typography>
                        <div style={{height: this.props.parent!=='trends' ? consts.heightGraph:consts.heightGraphTrends, overflow:'auto',width: '100%', clear:'both'}}>
                            {(this.state.visible && this.props.showTable == false) && (
                                <Plot 
                                    {...this.props.chartLayoutProps}
                                    data={this.props.chartData[this.props.model]}
                                    layout={{
                                        ...this.chartLayoutOptions,
                                        showlegend: this.props.showlegend,
                                        ...this.props.chartLayout[this.props.model]
                                    }}
                                    config={{
                                        ...this.props.chartConfigOptions,
                                        staticPlot: this.props.draggable
                                    }}
                                    useResizeHandler={true}
                                    style={ this.props.parent!=='trends' ? {width:'100%',...this.props.plotStyle}:{width:'100%',...this.props.plotStyle,height:consts.heightGraphTrends}}
                                    //ref="plotlyDash"
                                    onClick={this.handleClickOpenDetail}
                                />
                            )}
                            {(this.state.visible && this.props.showTable == true) && (
                                <div >{this.props.table}</div>)}
                        </div>
                    </CardContent>
                </Card>
                {this.props.haveEditMode === true ? (
                    <Dialog
                        fullScreen
                        open={this.state.open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleClose}
                    >   
                        <DialogTitle onClose={this.handleClose}>
                            {title}
                        </DialogTitle>
                        <DialogContent>
                            <Plot
                                data={this.props.chartData[this.props.model]}
                                layout={{
                                    ...this.chartLayoutOptionsFullScreen,
                                    ...this.props.chartLayout[this.props.model]
                                }}
                                useResizeHandler={true}
                                style={{width:'100%',height:'100%'}}
                            />
                        </DialogContent>
                    </Dialog>):null}
                {this.props.haveDialogTable === true ? (
                    <Dialog
                        fullScreen
                        open={this.state.openTable}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleCloseTable}
                    >
                        <DialogTitle onClose={() => this.handleCloseTable(this.props.id)}>
                            {this.props.tableDialogTitle}
                        </DialogTitle>
                        <DialogContent>
                        {this.props.selectData !== undefined && this.props.selectData !== null && (
                                            <div style={{width: '200px', float: 'right'}}>
                                            <Select 
                                            value={this.props.selectedId} onChange={this.props.selectedChange} 
                                            options={this.props.selectData.map((obj) => ({value:obj.url, label:obj.name}))} />
                                        </div>
                                        )}
                            {this.props.table}
                        </DialogContent>
                    </Dialog>):null}

                {this.props.haveDetailDialog === true ? (
                    <Dialog
                        fullScreen
                        open={this.props.openDetailDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.props.handleCloseDetail}
                    >
                        <DialogTitle onClose={() => this.props.handleCloseDetail(this.props.id)}>
                            {this.props.titleDialogDetail}
                        </DialogTitle>
                        <DialogContent>
                            {this.props.contentDialogDetail(this.state.detailData)}
                        </DialogContent>
                    </Dialog>):null}
            </div>
        );
    }
}

ChartCard.defaultProps = {
    statIconColor: 'gray',
    chartColor: 'purple',
    haveDialog: true,
    haveEditMode: true,
    haveDialogTable: false,
    haveConfigModal: true,
    haveVisibilityButton: false,
    plotStyle:{height:consts.heightGraph},
    showlegend:false,
    showTable:false,
    chartConfigOptions:{
        displayModeBar: false
    },
    toolBox: true,
    listModel: [],
    listCharts: [],
    selectedId:''
};

ChartCard.propTypes = {
    classes: PropTypes.object.isRequired,
    chartData: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.object.isRequired,
    ]),
    chartLayout: PropTypes.object,
    title: PropTypes.node,
    text: PropTypes.node,
    id: PropTypes.string,
    haveDialog: PropTypes.bool,
    haveEditMode: PropTypes.bool,
    haveConfigModal: PropTypes.bool,
    haveDialogTable:PropTypes.bool,
    haveVisibilityButton:PropTypes.bool,
    tableDialogTitle:PropTypes.string,
    table:PropTypes.object,
    tableDialogExtra:PropTypes.object,
    showlegend:PropTypes.bool,
    showTable:PropTypes.bool,
    plotStyle:PropTypes.object,
    chartConfigOptions:PropTypes.object,
    chartColor: PropTypes.oneOf(['orange', 'green', 'red', 'blue', 'purple']),
    isDrag: PropTypes.func,
    draggable: PropTypes.bool,
    openConfig: PropTypes.func,
    toolBox: PropTypes.bool,
    
    model: PropTypes.string,
    obj: PropTypes.object,
    listModel: PropTypes.array,
    listCharts: PropTypes.array
};

export default withStyles(chartCardStyle)(ChartCard);
