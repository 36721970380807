// ##############################
// // // App styles
// #############################

const loginStyle = theme => ({
  wrapper: {
    width: "420px",
    margin: "3em",
    zIndex: 99,
    display: "block",
    background: "white",
    textAlign: "center",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
  },
  header: {
    backgroundColor: "#2c2e3e",
    padding: "50px",
  },
  logoImage: {
    maxWidth:"100%"
  },
  container: {
    padding:"0 20px 30px"
  },

 });

export default loginStyle;
