// ##############################
// // // StatsCard styles
// #############################

import {
  card,
  defaultFont,
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader
} from "assets/jss/material-dashboard-react.jsx";

const settingsCardStyle = {
  card,
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  cardContent: {
    textAlign: "center",
    paddingTop: "10px",
    padding: "15px 20px",
    overflow: "visible"
  },
  cardIcon: {
    width: "80%",
    height: "75px",
    fill: "#999",
    margin:"10px auto"
  },

  cardTitle: {
    margin: "0",
    ...defaultFont,
    fontSize: "1.325em",
    color: "#777"
  },
  cardTitleSmall: {
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1",
    color: "#777"
  },

};

export default settingsCardStyle;
