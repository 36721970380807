import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FixedChardCard } from 'components';
import dashboardStyle from 'assets/jss/material-dashboard-react/dashboardStyle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withStyles, Grid } from '@material-ui/core';
import {typesOfGraphs} from '../../consts/graphs';

const reorder = (list, source, destination) => {
    const result = Array.from(list);
    let removed;
    result.map((item, index) => {
        if (item.id === source.droppableId) {
            [removed] = result[index].taskIds.splice(source.index, 1);
        }
    });
    result.map((item, index) => {
        if (item.id === destination.droppableId) {
            result[index].taskIds.splice(destination.index, 0, removed);
        }
    });
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? 'lightgreen' : '#f9f9f9',
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#f9f9f9',
    display: 'flex',
    overflow: 'auto',
});



class ContainerChartCard extends Component {
    constructor(props) {
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result) {

        if (!result.destination) {
            return;
        }
        const rows = reorder(
            this.props.dataConfig,
            result.source,
            result.destination
        );
        this.props.changeRows(rows);
    }
    
    render() {
        return (
            <div ref={this.myRef}>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    {this.props.dataConfig.map((row, indexRow) =>
                        <div key={row.id}>
                            {/* {'--'}{row.taskIds} */}
                            <Droppable droppableId={row.id} direction="horizontal" >
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                        {...provided.droppableProps}
                                    >
                                        <Grid container >
                                            {row.taskIds.map((task, indexTask) => {
                                                let item, model, showlegend, showTable;
                                                
                                                if(task == null){
                                                    return;
                                                }

                                                if(typeof task == "object"){
                                                    
                                                    item = typesOfGraphs.find((obj) => obj.id === task.chartId)
                                                    model = task.chart1.model
                                                    showlegend = task.showlegend
                                                    showTable = task.showTable
                                                }else{
                                                    item = typesOfGraphs.find((obj) => obj.id === task)
                                                    model = item.model
                                                }
                                                return <Grid item xs key={`${indexTask}-${item.id}`} style={{ padding: 15 }}>
                                                    <Draggable key={item.id} 
                                                        draggableId={`${item.id}-${row.id}-${indexTask}`}
                                                        index={indexTask}
                                                        isDragDisabled={!this.props.draggable}
                                                    >
                                                        
                                                        {(provided, snapshot) => (
                                                            <div 
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <FixedChardCard
                                                                    elem={item.id}
                                                                    chartType={item.id}
                                                                    model={model}
                                                                    obj={task}
                                                                    showlegend={showlegend}
                                                                    showTable={showTable}
                                                                    data={this.props.data}
                                                                    isDrag={this.props.isDrag}
                                                                    draggable={this.props.draggable}
                                                                    openConfig={this.props.openConfig}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </Grid>;
                                            })}
                                                
                                        </Grid>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    )} 
                </DragDropContext>
            </div>
        );
    }
}

ContainerChartCard.defaultProps = {
    haveVisibilityButton: false
};

ContainerChartCard.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.object.isRequired,
    ]),
    dataConfig: PropTypes.array,
    haveVisibilityButton: PropTypes.bool,
    openConfig: PropTypes.func
};

export default withStyles(dashboardStyle)(ContainerChartCard);