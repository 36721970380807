// ##############################
// // // Dashboard styles
// #############################

import { successColor } from 'assets/jss/material-dashboard-react.jsx';

const dashboardStyle = {
    successText: {
        color: successColor
    },
    upArrowCardCategory: {
        width: 14,
        height: 14
    },
    stickTop: {
        width: '100%',
        position: 'sticky',
        top: -15,
        zIndex: 1
    },
    addIcon:{
        position:'absolute',
        right:"10px",
        top:"60px"
    }
};

export default dashboardStyle;
