import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loading, loaded, error, logout } from '../../redux/actions/auth';
import axios from 'axios';
import consts from '../../const';

class PrivateRoute extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true  
        };
    }
    loadUser () {
        this.props.loading();
        const token = localStorage.getItem('token');
        let headers = {
            'Content-Type': 'application/json',
        };
        if (token) {
            axios.post(`${consts.API_BASE_URL}/api/auth/verify/`, { headers, token})
                .then(res => {
                    if (res.status === 200) {
                        this.props.loaded(true);
                        this.setState({
                            loading: false
                        });
                        return res.data;
                    } else if (res.status >= 400 && res.status < 500) {
                        this.props.logout();
                        this.props.history.push('/user/login');
                    }
                }).catch(res => {
                    this.props.logout();
                    this.props.history.push('/user/login');
                });
        } else {
            this.props.loaded(false);
            this.setState({
                loading: false
            });
        }
    }

    componentDidMount() {
        this.loadUser();
    }

    render() {
        return (
            <div>{ (this.state.loading) ?
                null : 
                (this.props.isAuthenticated) ?
                    <Route
                        path={this.props.path}
                        component={this.props.component}
                    /> :
                    <Redirect
                        to='/user/login' />
            }
            </div>
        );
    }
}

PrivateRoute.propTypes = {
    path: PropTypes.string,
    component: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    token: PropTypes.string,
    history: PropTypes.object,

    loading: PropTypes.func,
    loaded: PropTypes.func,
    error: PropTypes.func
};

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        token: state.auth.token
    };
}

const mapDispatchToProps = {
    loading,
    loaded,
    error, logout
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);