import { apiRequestorHelper } from '../helpers'
import consts from '../const'

export default {
	get: () => {
		return apiRequestorHelper({
			url: consts.API_BASE_URL + '/api/users/me',
			method: 'get',
		})
	},
	put: ({data,filters,version,date,showItemsSold}) => {
		return apiRequestorHelper({
			url: consts.API_BASE_URL + '/api/users/me',
			method: 'put',
			body:{
				data,
				filters,
				version,
				date,
				showItemsSold,
			}
		})
	},
}