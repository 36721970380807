
import React from 'react';
import axios from 'axios';
import consts from '../../const';
import Plot from 'react-plotly.js';
import { connect } from 'react-redux';


class SalesPerTime extends React.Component{
    state = {
        data: false
    }
    componentDidMount(){
        this.load()
    }
    load(){
        let headers = {
            Authorization: `JWT ${localStorage.getItem('token')}`
        };

        let dates = {};

        dates = {
            startDate: this.props.startDate.format(),
            endDate: this.props.endDate.format()
        };
        axios.get(consts.API_BASE_URL + '/api/business/stadistics?bytime=1&product_id='+this.props.obj.id, {params: dates, headers}).then(({data}) => {
            this.setState({data});
        })
        
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.obj.id !== prevProps.obj.id || this.state.data === false)
            this.load()            
    }
    render(){
        if(this.state.data)
            return (
                <Plot
                    data={[{
                        y: this.state.data.bytime.map((obj) => obj['total']),
                        x: this.state.data.bytime.map((obj) => obj['hour']),
                        type: 'bar',
                    }]}
                    ref="plotlyDialog"
                    useResizeHandler={true}
                    style={{width:'100%',height:'100%'}}
                />);
        return (<p>Loading</p>);
    }
}
function mapStateToProps(state) {
    return {
        startDate: state.calendar.startDate,
        endDate: state.calendar.endDate,
        optionPerDay: state.calendar.optionPerDay,
        groupBy: state.calendar.groupBy,
        timeSelect: state.calendar.timeSelect
    };
}

export default connect(mapStateToProps)(SalesPerTime);