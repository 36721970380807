import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {Store} from '@material-ui/icons';
import {withStyles, Grid} from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';
import { StatsCard, ChartCard,
    RegularCard,
    Loading,
    ItemGrid, Calendar,
    MessageSnackbar
} from 'components';
import Select  from 'react-select';
import { connect } from 'react-redux';
import trendsStyle from 'assets/jss/material-dashboard-react/trendsStyle';
import consts from 'const';
import moment from 'moment';


class Trends extends React.Component {
    selectAll = {value:null, label:'Select All'};
    state = {
        startDate: moment().set({
            'hour': 0,
            'minute': 0,
            'second': 0
        }),
        endDate: moment().set({
            'hour': 23,
            'minute': 59,
            'second': 59
        }),
        peerday:'',
        value: 0,
        category:this.selectAll,
        product:this.selectAll,
        groupBy:{ value: 'day', label: 'Day' },
        loading:true,
        message: false,
        compare: {value:"false", label:"False"},
        onMessage: {
            style: '',
            msg: ''
        },
        business: {},
        categories: [],
        products: [],
        temperature: [],
        stadistics: {data: [], info:{}},
        stadisticsCompare: {data: [], info:{}}
    };
    handleChange = name => event => {
        if(name === 'category'){
            this.setState({ [name]: event, product:this.selectAll });
        }
    
        else
            this.setState({ [name]: event });
    };

    componentDidUpdate(prevProps, prevState){
        if(prevProps.endDate !== this.props.endDate ||
            prevProps.startDate !== this.props.startDate||
            prevProps.groupBy !== this.props.groupBy ||
            prevState.product !== this.state.product ||
            prevState.category !== this.state.category ||
            prevProps.optionPerDay !== this.props.optionPerDay ||
            prevProps.timeSelect !== this.props.timeSelect 
        ){

            // let test = this.state.categories.map((obj) => {    
            //     return  {value:obj.url, label:obj.name};
            // })  //just for testing categories EH


            if (this.props.timeSelect === 'All') {
                this.setState({ loading: true }, () => {
                    this.fetchData(true);
                });

            } else {
                this.setState({ loading: true }, () => {
                    this.fetchData();
                    if(this.state.compare.value !== "false"){
                        this.fetchDataCompare();
                    }
                });
            }
        }
        // if(prevState.compare !== this.state.compare){
        //     if(this.state.compare !== "false"){
        //         this.fetchDataCompare();
        //     }

        // }
    }
    fetchDataCompare() {
        debugger
        let dates = {};
        if(this.state.compare.value === "month"){
            dates = {
                startDate: moment(this.props.startDate).subtract(1, 'months').format(),
                endDate: moment(this.props.endDate).subtract(1, 'months').format()
            };
        }else{
            dates = {
                startDate:moment(this.props.startDate).subtract(1, 'weeks').format(),
                endDate: moment(this.props.endDate).subtract(1, 'weeks').format()
            };
        }
        let headers = {
            Authorization: `JWT ${localStorage.getItem('token')}`
        };
        if (this.props.optionPerDay !== 'all days'){
            dates['peerday'] = this.props.optionPerDay;
        }
        dates['groupby'] = this.props.groupBy;
        // dates['bytime'] = 1

        if(this.state.product )
            dates['product'] = this.state.products.find(obj => obj.url === this.state.product.value).id;
        if(this.state.category )
            dates['category'] = this.state.categories.find(obj => obj.url === this.state.category.value).id;
        axios.all([
            axios.get(consts.API_BASE_URL + '/api/business/stadistics', { params: dates, headers})
        ]).then(([ businessStadistics  ]) => {
            this.setState({
                stadisticsCompare: businessStadistics.data,
                loading:false
            });
        }).catch(error => {
            this.setState({
                loading: false,
                message: true,
                onMessage: {
                    style: 'warning',
                    msg: 'Loading errorr, please try again.',
                }
            });
        });
    }

    fetchData(allData) {

        console.log("fetchData")
        let dates = {};
        if (!allData) {
            dates = {
                startDate: this.props.startDate.format(),
                endDate: this.props.endDate.format()
            };
        }
        let headers = {
            Authorization: `JWT ${localStorage.getItem('token')}`
        };
        if (this.props.optionPerDay !== 'all days'){
            dates['peerday'] = this.props.optionPerDay;
        }
        dates['groupby'] = this.props.groupBy;


        if(this.state.product )
            dates['product'] = this.state.product.id;
        if(this.state.category ){
            dates['category'] = this.state.category.id;
        }
        
        axios.all([
            axios.get(consts.API_BASE_URL + '/api/business/stadistics', { params: dates, headers})
        ]).then(([ businessStadistics  ]) => {
            this.setState({
                stadistics: businessStadistics.data,
                loading:false
            });
        }).catch(error => {
            this.setState({
                loading: false,
                message: true,
                onMessage: {
                    style: 'warning',
                    msg: 'Loading errorr, please try again.',
                }
            });
        });
    }

    componentDidMount() {
        let dates = {};
        let headers = {
            Authorization: `JWT ${localStorage.getItem('token')}`
        };
        if (this.props.startDate && this.props.endDate) {
            dates = {
                startDate: this.props.startDate.format(),
                endDate: this.props.endDate.format()
            };
        }
      
        if (this.props.optionPerDay !== 'all days') {
            dates['peerday'] = this.props.optionPerDay;
        }
        dates['groupby'] = this.props.groupBy;
        // dates['bytime'] = 1;


        axios.all([
            axios.get(consts.API_BASE_URL + '/api/business/2/', { headers }),
            axios.get(consts.API_BASE_URL + '/api/categories/', { headers }),
            axios.get(consts.API_BASE_URL + '/api/products/', { headers }),
            axios.get(consts.API_BASE_URL + '/api/temperature', { headers }),
            axios.get(consts.API_BASE_URL + '/api/business/stadistics', { params: dates, headers })
        ]).then(([businessData, categories, products, temperature, businessStadistics  ]) => {
            categories.data.unshift({url:null, name:'Select All'});
            products.data.unshift({url:null, name:'Select All', categories:[]});
            this.setState({
                business: businessData.data,
                categories: categories.data,
                products: products.data,
                temperature: temperature.data,
                stadistics: businessStadistics.data,
                loading: false
            });
        }).catch(error => {
            this.setState({
                loading: false,
                message: true,
                onMessage: {
                    style: 'warning',
                    msg: 'Loading errorr, please try again.',
                }
            });
        });
        document.title = 'Trends';
    }

    getProducts(category){
        if(category.value === null)
            return [];
        let array = this.state.products.filter((obj)=>{
            return obj.categories.indexOf(category.value) > -1;
        });
        array = array.map((obj, key) => {
            return {value:obj.url, label:obj.name};
        });
        array.unshift(this.selectAll);
        return array;
    }

    getDateRange(){
        if(this.state.stadistics.data.length > 0){
            // const testRange = [this.state.stadistics.data[0].created, this.state.stadistics.data[this.state.stadistics.data.length-1].created];    //EH
            // if (this.state.stadistics.data[0].created === this.state.stadistics.data[this.state.stadistics.data.length-1].created)
            //     return [this.state.stadistics.data[0].created]
            // else         
                return [this.state.stadistics.data[0].created, this.state.stadistics.data[this.state.stadistics.data.length-1].created];
        }
    }


    getDateCompareRange(){
        if(this.state.stadisticsCompare.data.length > 0){
            return [this.state.stadisticsCompare.data[0].created, this.state.stadisticsCompare.data[this.state.stadisticsCompare.data.length-1].created];
        }
    }

    refresh = () => {
        this.setState({
            loading: true,
        }, () => {
            this.fetchData();
        });
    }

    handleChangeCatProd = (field,instance) => {
        if (field == "category")
        this.setState({
            [field]: instance
        })
        
    }
    
    render() {
        const listModel = ['custom'];
        let startDateCompare, endDateCompare;
        if(this.state.compare.value === "month"){
            startDateCompare= moment(this.props.startDate).subtract(1, 'months');
            endDateCompare= moment(this.props.endDate).subtract(1, 'months');
        }else{
            startDateCompare = moment(this.props.startDate).subtract(1, 'weeks');
            endDateCompare = moment(this.props.endDate).subtract(1, 'weeks');
        }
        console.log(this.state.stadisticsCompare.data.map((obj) => obj['total']));
        return (
            <div>
                <Loading
                    open={this.state.loading}
                />
                <MessageSnackbar
                    open={this.state.message}
                    style={this.state.onMessage.style}
                    msg={this.state.onMessage.msg}
                    close={() => this.setState({ message: false })}
                />
                <Grid container>
                    {/* <ItemGrid xs={12} sm={6} md={3}>
                        <RegularCard
                            content={
                                <form>
                                    <Select
                                        value={this.state.category}
                                        onChange={this.handleChange('category')}
                                        placeholder={'Select a Category'}
                                        options={
                                            this.state.categories.map((obj) => { 
                                            return  {value:obj.url, label:obj.name};
                                        })
                                        } 
                                    />

                                    <Select
                                        value={this.state.product}
                                        onChange={this.handleChange('product')}
                                        placeholder={'Select a Product'}
                                        options={this.getProducts(this.state.category)} />
                                    <Select
                                        value={this.state.groupBy}
                                        onChange={this.handleChange('groupBy')}
                                        placeholder={'Group By'}
                                        options={[
                                            { value: 'day', label: 'Day' },
                                            { value: 'week', label: 'Week' },
                                            { value: 'month', label: 'Month' },
                                            { value: 'year', label: 'Year' },
                                        ]} />
                                    <Select
                                        value={this.state.compare}
                                        onChange={this.handleChange('compare')}
                                        placeholder={'Compare'}
                                        options={[
                                            { value: "week", label: 'Previous Week' },
                                            { value: "month", label: 'Previous Month' },
                                            { value: "false", label: 'False' },
                                        ]} />

                                </form>
                            }
                        />
                    </ItemGrid> */}
                    <ItemGrid xs={12} sm={12} md={12}>
                        <RegularCard
                            content={
                                <Calendar
                                    refresh={this.refresh}
                                    categories = {this.state.categories}
                                    category = {this.state.category}
                                    products = {this.getProducts(this.state.category)}
                                    product = {this.state.product}
                                    onChangeCatProd = {this.handleChangeCatProd} 
                                />
                            }
                        />
                    </ItemGrid>

                </Grid>
                <Grid container >
                    <ItemGrid xs={12} sm={12} md={10}>
                        <ChartCard
                            // plotStyle={{width:'100%',height:'500px',marginTop:'30px'}}
                            parent = "trends"
                            title={'Graph from '+( (this.props.startDate !== null) ? this.props.startDate.format('MM/DD/YYYY') : 'start')
                          +' to '+( (this.props.endDate !== null) ? this.props.endDate.format('MM/DD/YYYY') : 'end')}
                            showlegend={true}
                            chartConfigOptions={{}}
                            model={listModel[0]}
                            haveDialog={false}
                            draggable={true}
                            haveEditMode={true}
                            haveConfigModal={false}
                            chartData={{
                                custom: [
                    
                                    {
                                        type: 'bar',
                                        x: this.state.stadistics.data.map((obj) => obj['created']),
                                        y: this.state.stadistics.data.map((obj) => obj['total']),
                                        text: this.state.stadistics.data.map((obj) => '$ '+Number(obj['total']).toFixed(2)),
                                        // hovertext: this.state.stadistics.data.map((obj) => '$ '+Number(obj['total']).toFixed(2)),
                                        hoverinfo:"x+text",
                                        name: 'Net Payment',
                                        xaxis: 'x',
                                        marker:{
                                            color:'orange'
                                        },
                                        mode: 'markers',
                                        // textposition: 'inside',

                                    },
                                    {
                                        x: (() => {
                                            const xTest = this.state.stadistics.data.map((obj) => obj['created'])
                                            return this.state.stadistics.data.map((obj) => obj['created'])
                                        })(),
                                        y: this.state.stadistics.data.map((obj) => obj['count']),
                                        text: this.state.stadistics.data.map((obj) => obj['count']),
                                        type: 'scatter',
                                        mode: 'markers+lines',
                                        marker: {color: 'red'},
                                        name: 'Count',
                                        xaxis: 'x',
                                        yaxis: 'y3',
                                        // textposition: 'right',
                                    },
                                    {
                                  
                                        x: this.state.temperature.map((obj) => obj['day']),
                                        y: this.state.temperature.map((obj) => obj['thermometer']),
                                        type: 'scatter',
                                        mode: 'bar',
                                        name: 'Temperature',
                                        xaxis: 'x',
                                        yaxis: 'y2',
                                        marker: {color: 'rgb(234, 153, 153)',}
                                    }
                                    ,{
                                        x: this.state.temperature.map((obj) => obj['day']),
                                        y: this.state.temperature.map((obj) => obj['rain_rate']),
                                        type: 'scatter',
                                        mode: 'bar',
                                        name: 'Rain',
                                        xaxis: 'x',
                                        yaxis: 'y4',
                                        marker: {color: 'rgb(164, 194, 244)',}
                                    },
                                ]
                            }}
                            chartLayout={{
                                custom: {
                                    hovermode:'closest',
                                    autosize: true,
                                    margin: {
                                        l: 40,
                                        r: 60,
                                        b: 20,
                                        t: 0,
                                        pad: 10,
                                        autoexpand:true
                                    },
                                    legend: {'orientation': 'h'},
                                    xaxis1: {
                                        range: this.getDateRange(),
                                        tickformat: '%d/%m/%y',
                                        type: 'date',
                                    },
                       
                                    yaxis: {
                                        domain: [0.50, 1.9],
                                        tickformat:'$',
                                        autorange:true
                                    },
                                    yaxis3: {
                                        domain: [0.50, 1.9],
                                        overlaying: 'y1',
                                        side: 'right',
                                        autorange:true
                          
                                    },
                                    yaxis2: {
                                        domain: [0, 0.25],
                                        anchor: 'x',
                                        autorange:true,
                                    },
                                    yaxis4: {
                                        domain: [0, 0.25],
                                        anchor: 'x',
                                        overlaying: 'y2',
                                        side: 'right',
                                        autorange:true
                                    },
                                }
                            }}
                            chartColor="green"
                        />
                        {this.state.compare.value !== "false" && (
                        <ChartCard
                            plotStyle={{width:'100%',height:'500px',marginTop:'30px'}}
                            title={'Graph from '+( (this.props.startDate !== null) ? startDateCompare.format('MM/DD/YYYY') : 'start')
                          +' to '+( (this.props.endDate !== null) ? endDateCompare.format('MM/DD/YYYY') : 'end')}
                            showlegend={true}
                            chartConfigOptions={{}}
                            model={listModel[0]}
                            haveDialog={false}
                            draggable={true}
                            haveEditMode={true}
                            haveConfigModal={false}
                            chartData={{
                                custom: [
                                    {
                                        x: this.state.stadisticsCompare.data.map((obj) => obj['created']),
                                        y: this.state.stadisticsCompare.data.map((obj) => obj['count']),
                                        type: 'scatter',
                                        mode: 'lines+points',
                                        marker: {color: 'red'},
                                        name: 'Count',xaxis: 'x',
                                    },
                                    {
                                        type: 'bar',
                                        x: this.state.stadisticsCompare.data.map((obj) => obj['created']),
                                        y: this.state.stadisticsCompare.data.map((obj) => obj['total']),
                                        yaxis: 'y2',
                                        name: 'Net Payment',
                                        xaxis: 'x',

                                    },
                                ]
                            }}
                            chartLayout={{
                                custom: {
                                    margin: {
                                        l: 40,
                                        r: 60,
                                        b: 20,
                                        t: 0,
                                        pad: 0,
                                        autoexpand:true
                                    },legend: {'orientation': 'h'},
                                    xaxis: {
                                        range: this.getDateCompareRange(),
                                        type: 'date'
                                    },
                                    yaxis2: {
                                        overlaying: 'y',
                                        side: 'right',
                                        tickformat: '$'
                                      }
                                   
                                }
                            }}
                            chartColor="green"
                        />)}
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={2}>
                        <StatsCard
                            icon={Store}
                            iconColor="orange"
                            title="Net revenue"
                            description={
                                <CurrencyFormat
                                    value={this.state.stadistics.info.total || 0}
                                    displayType={'text'} thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={'$'}/>
                            }
                            statText={
                                <span>{this.state.stadistics.info.count || 0} count</span>
                            }
                        />
                    </ItemGrid>
                </Grid>


            </div>
        );
    }
}

Trends.propTypes = {
    classes: PropTypes.object.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    optionPerDay: PropTypes.string,
    groupBy: PropTypes.string,
    timeSelect: PropTypes.string,
    live: PropTypes.bool,

};

function mapStateToProps(state) {
    return {
        startDate: state.calendar.startDate,
        endDate: state.calendar.endDate,
        optionPerDay: state.calendar.optionPerDay,
        groupBy: state.calendar.groupBy,
        timeSelect: state.calendar.timeSelect,
        live: state.calendar.live
    };
}

export default connect(mapStateToProps)(withStyles(trendsStyle)(Trends));
