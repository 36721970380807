// ##############################
// // // Dashboard styles
// #############################

import { successColor } from "assets/jss/material-dashboard-react.jsx";

const trendsStyle =  theme => ({
  successText: {
    color: successColor
  },
  formControl:{
    minWidth: "100%",
  },
  selectControl:{
    root:{
      fontSize: "0.8rem",
    }
  }
});

export default trendsStyle;
