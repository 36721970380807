import React from 'react';
import PropTypes from 'prop-types';
import { 
    Error, 
    AddAlert, 
    CheckCircle,
} from '@material-ui/icons';

import Snackbars from 'components/Snackbar/Snackbar.jsx';

class MessageSnackbar extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            place: 'br',
            open: false,
            style: {
                warning: {
                    color: 'warning',
                    icon: Error
                },
                success: {
                    color: 'success',
                    icon: CheckCircle
                },
                info: {
                    color: 'info',
                    icon: AddAlert
                }
            }
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.setState((state, props) => ({
                propsStyle: state.style[props.style]
            }));

            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }

            this.timeOut = setTimeout(function () {
                this.props.close();
            }.bind(this), 5000);
        }
    }
    
    render() {
        return (
            <Snackbars
                place={this.state.place}
                color={this.state.propsStyle ? this.state.propsStyle.color : 
                    this.state.style.info.color}
                icon={this.state.propsStyle ? this.state.propsStyle.icon : 
                    this.state.style.info.icon}
                message={this.props.msg}
                open={this.props.open}
                closeNotification={() => this.setState({ open: false })}
                close
            />
        );
    }
}

MessageSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    msg: PropTypes.string,
    style: PropTypes.string
};

export default MessageSnackbar;
