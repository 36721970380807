import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import consts from "const";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

class StoresList extends React.Component  {
  state = {
      list:[]
  };
  fetchData() {
    axios.get(consts.API_BASE_URL + "/api/business/").then(
        ({data}) => {
            this.setState({list: data})
        }).catch(function (error) {
        console.log(error);
    });
  }
  componentDidMount() {
    this.fetchData();
  }
  render() {
  return (
    <Paper className={this.props.classes.root}>
      <Table className={this.props.classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Latitude</TableCell>
            <TableCell>Longitude</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.list.map(n => {
            return (
              <TableRow key={n.id}>
                <TableCell component="th" scope="row">
                  {n.name}
                </TableCell>
                <TableCell>{n.phone}</TableCell>
                <TableCell>{n.address}, {n.city}</TableCell>
                <TableCell>{n.latitude}</TableCell>
                <TableCell>{n.longitude}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}
}

StoresList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StoresList);
