import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

import { Header, Footer, Sidebar } from 'components';

import dashboardRoutes from 'routes/dashboard.jsx';

import appStyle from 'assets/jss/material-dashboard-react/appStyle.jsx';

import image from 'assets/img/sidebar-2.jpg';
import logo from 'assets/img/logo.png';

const switchRoutes = (
    <Switch>
        {dashboardRoutes.map((prop, key) => {
            if (prop.redirect)
                return <Redirect from={prop.path} to={prop.to} key={key} />;
            return <Route path={prop.path} component={prop.component} key={key} />;
        })}
    </Switch>
);

class App extends React.Component {
  state = {
      mobileOpen: false
  };
  handleDrawerToggle = () => {
      this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
      return this.props.location.pathname !== '/maps';
  }

  componentDidUpdate() {
      this.refs.mainPanel.scrollTop = 0;
  }
  render() {
      const { classes, ...rest } = this.props;
      return (
          <div className={classes.wrapper}>
              <Sidebar
                  routes={dashboardRoutes}
                  logoText={'Creative Tim'}
                  logo={logo}
                  image={image}
                  handleDrawerToggle={this.handleDrawerToggle}
                  open={this.state.mobileOpen}
                  color="purple"
                  {...rest}
              />
              <div className={classes.mainPanel} ref="mainPanel">
                  <Header
                      routes={dashboardRoutes}
                      handleDrawerToggle={this.handleDrawerToggle}
                      {...rest}
                  />
                  {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                  {this.getRoute() ? (
                      <div className={classes.content}>
                          <div className={classes.container}>{switchRoutes}</div>
                      </div>
                  ) : (
                      <div className={classes.map}>{switchRoutes}</div>
                  )}
                  {this.getRoute() ? <Footer /> : null}
              </div>
          </div>
      );
  }
}

App.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(App);
