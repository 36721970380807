import React from "react";
import {
  withStyles, Menu, MenuItem, FormControlLabel, Checkbox
} from "@material-ui/core";
import {Button } from "components";
import PropTypes from "prop-types";

import categoryFilterButtonStyle from "assets/jss/material-dashboard-react/categoryFilterButtonStyle";

class CategoryFilterButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
    };
  }

  componentWillReceiveProps(){
  }

  componentDidMount(){
   
  }


  componentDidUpdate(prevProps, prevState, snapshot){

  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleChange = (id, event)  => {
    var catArray = this.props.categoriesHidden
    var index = this.props.categoriesHidden.indexOf(id);

    if( index >= 0) {
      catArray.splice(index, 1);
      this.props.onCategorySelected(catArray)
    }else {
      catArray.push(id);
      this.props.onCategorySelected(catArray)
    }
  };
  render() {
    const { anchorEl} = this.state;
    const { categories, categoriesHidden } = this.props;
    return (
      <span>
        <Button
                aria-owns={anchorEl ? 'category-filter-menu' : null}
                onClick={this.handleClick}
                small style={{"padding": "0 8px", margin: 0, marginRight: "6px"}} color="info">Filter</Button>
        <Menu
          id="category-filter-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {categories.map((category)=>{
            return (
              <MenuItem key={category.id}>
                <FormControlLabel control={ <Checkbox
                  checked={categoriesHidden.indexOf(category.id) < 0 }
                  onChange={ event => this.handleChange(category.id, event)} /> } label={category.name} />
              </MenuItem>
            )
          })}
          <MenuItem onClick={this.handleClose}>Save Preference</MenuItem>
        </Menu>
      </span>
    );
  }
}

CategoryFilterButton.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object),
  onCategorySelected: PropTypes.func
};

export default withStyles(categoryFilterButtonStyle)(CategoryFilterButton);
