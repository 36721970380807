import React from 'react';
import axios from 'axios';
import {Grid, TableRow, TableCell, withStyles, FormControlLabel, Checkbox} from '@material-ui/core';
import { Loading, 
    RegularCard, 
    Calendar, 
    Table, 
    ItemGrid, 
    Button, 
    CategoryFilterButton, 
    MessageSnackbar
} from 'components';
import consts from 'const';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import CurrencyFormat from 'react-currency-format';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { localStorageService } from '../../services';
import { getUserMePromise,putUserMePromise } from '../../promises';




import reportsStyle from 'assets/jss/material-dashboard-react/reportsStyle';

let tableHead = [
    {'column': 'name', 'label': 'Name'},
    {'column': 'solds', 'label': 'Sold'},
    {'column': 'refundeds', 'label': 'Refunded'},
    {'column': 'revenues', 'label': 'Item Revenue'},
    {'column': 'modifiers_revenue', 'label': 'Modifiers Reveneue'},
    {'column': 'order_discount', 'label': 'Order Discounts'},
    {'column': 'totals', 'label': 'Total'},
];



const CURRENT_VERSION=2;

class SummarySales extends React.Component {

    state = {
        loading: true,
        message: false,
        onMessage: {
            style: '',
            msg: ''
        },
        showItemsSold: false,
        data: [],
        categoriesHidden: [],
    };
    handleChange = name => event => {
        this.setState({[name]: event.target.checked},()=>{
            if (name == 'showItemsSold'){
                this.postConfigDashboard();
            }
        });
   
    };


    fetchData() {
        let dates = {};
        if (this.props.startDate && this.props.endDate) {
            dates = {startDate: this.props.startDate.format(), endDate: this.props.endDate.format()};
        }
        if (this.props.peerday) {
            dates['peerday'] = this.props.peerday;
        }

        let headers = {
            Authorization: `JWT ${localStorage.getItem('token')}`
        };

        axios.get(consts.API_BASE_URL + '/api/items_reports/',  {params: dates, headers})
            .then(({data}) => {
                this.setState({
                    loading: false,
                    data
                });
            }).catch(error => {
                this.setState({
                    loading: false,
                    message: true,
                    onMessage: {
                        style: 'warning',
                        msg: 'Loading errorr, please try again.',
                    }
                });
            });
    }

    componentDidMount() {
        this.getConfig();
        this.fetchData();
        document.title = 'Summary Sales';
    }

    getConfig() {
        if (localStorage.getItem('showItemsSold') != 'undefined'){
            let showItemsSold= JSON.parse(localStorage.getItem('showItemsSold'));
            this.setState({showItemsSold,});
        }
        if (localStorage.getItem('categoriesHidden') != 'undefined'){
            let categoriesHidden= JSON.parse(localStorage.getItem('categoriesHidden'));
            this.setState({categoriesHidden,});
        }

        // if(version < CURRENT_VERSION){
        //     dataTab=null;
        // }
        // let xx = JSON.parse(localStorage.getItem('showItemsSold'))
        getUserMePromise().then(res => {});
    }


    postConfigDashboard() {
        if (localStorage.getItem('dataTab') != 'undefined'){
            var dataTab = JSON.parse(localStorage.getItem('dataTab'));
        }else{
            var dataTab = undefined;
        }
        if (localStorage.getItem('filters') != 'undefined'){
            var filters = JSON.parse(localStorage.getItem('filters'));
        }else{
            var filters = undefined;
        }


        localStorage.setItem('showItemsSold', JSON.stringify(this.state.showItemsSold));
        localStorage.setItem('categoriesHidden', JSON.stringify(this.state.categoriesHidden));
        


        // if (this.state.showItemsSold != undefined){
        //     localStorage.setItem('showItemsSold', JSON.stringify(this.state.showItemsSold));
        // }
  
        // let version = localStorage.getItem('dataVersion');
        // let token = localStorage.getItem('token');
        let d = new Date();
        // let headers = {
        //     Authorization: `JWT ${localStorage.getItem('token')}`
        // };
        putUserMePromise({
            data:{
                data:dataTab,
                filters:filters, 
                version:CURRENT_VERSION, 
                date:d.toUTCString(),
                showItemsSold:this.state.showItemsSold,
                categoriesHidden:this.state.categoriesHidden
            }
        }).then(res => {
            console.log(res);
        });

    //     axios.put(`${consts.API_BASE_URL}/api/users/me`, {data:{data:dataTab,filters:filters, version:CURRENT_VERSION, date:d.toUTCString(),showItemsSold:this.state.showItemsSold} }, {headers})
    //                 .then(res => {
    //                     console.log(res);
    //                 });
    }





    calcSolds(arreglo) {
        if (arreglo.length === 0)
            return 0;
        if (arreglo.length === 1) {
            return arreglo[0].solds;
        }
        var l = arreglo.reduce((total, num) => {
            if (typeof(total) === 'object')
                return total.solds + num.solds;
            else
                return total + num.solds;
        });
        console.log(l);
        return l;
    }
    calcTotal(arreglo) {
        if (arreglo.length === 0)
            return 0;
        if (arreglo.length === 1) {
            return arreglo[0].totals;
        }
        var l = arreglo.reduce((total, num) => {
            if (typeof(total) === 'object')
                return total.totals + num.totals;
            else
                return total + num.totals;
        });
        console.log(l);
        return l;
    }

    savePdf() {
        var doc = new jsPDF('l', 'pt');
        var columns = tableHead.map(obj => obj.label);
        let start = this.props.startDate === null ? 'Start' : this.props.startDate.format('YYYY-MM-DD');
        let end = this.props.endDate === null ? 'End' : this.props.endDate.format('YYYY-MM-DD');
        doc.text(`Summary Sales - L'Artisane - ${start} - ${end}`, 80, 200);
        doc.addPage();
        const {categoriesHidden} = this.state;

        let index = 0;
        this.state.data.forEach((category) => {
            let products = category.products.filter(product=>product.solds!=0);
            console.log('products length');
            console.log(products.length);
            if (categoriesHidden.indexOf(category.id) < 0 && products.length > 0) {
                var rows = this.state.showItemsSold ? 
                    products.map((obj) => {
                        if(obj.totals == null)
                            obj.totals = 0;
                        return [
                            obj.name, obj.solds, obj.refundeds,
                            obj.revenues, obj.modifiers_revenue, obj.order_discount,
                            obj.totals
                        ]; 
                    })
                    :
                    category.products.map((obj) => {
                        if(obj.totals == null)
                            obj.totals = 0;
                        return [
                            obj.name, obj.solds, obj.refundeds,
                            obj.revenues, obj.modifiers_revenue, obj.order_discount,
                            obj.totals
                        ]; 
                    });
            

                rows = rows.map((row) => {
                    return row.map(obj => {
                        if (typeof obj === 'number') {
                            obj = obj.toFixed(2);
                        }
                        return obj;
                    });
                });

                doc.setFontSize(18);
                doc.text(category.name, 40,
                    index === 0 ? 40 : doc.autoTable.previous.finalY + 40);
                doc.setFontSize(12);
                doc.autoTable(columns, rows, {
                    startY: index === 0 ? 55 : doc.autoTable.previous.finalY + 50,
                    bodyStyles: {fontSize: 7},
                    headerStyles: {fontSize: 9, fillColor: false, textColor: '#9c27b0'},
                });
                doc.setFontSize(8);
                doc.text('Total $' + this.calcTotal(category.products).toFixed(2) +' Total ' + this.calcSolds(category.products).toFixed(2) , 40,
                    doc.autoTable.previous.finalY + 20);

                index += 1;
            }
        });
        doc.save('SummarySales.pdf');
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.endDate !== this.props.endDate ||
            prevProps.startDate !== this.props.startDate ||
            prevProps.groupBy !== this.props.groupBy ||
            prevProps.optionPerDay !== this.props.optionPerDay ||
            prevProps.timeSelect !== this.props.timeSelect 
        ) {
            if (this.props.timeSelect === 'All') {
                this.setState({ loading: true }, () => {
                    this.fetchData(true);
                });
            } else {
                this.setState({loading: true}, () => {
                    this.fetchData();
                });
            }
        } 
    }

    render() {
        const {categoriesHidden, showItemsSold} = this.state;
        return (
            <div>
                <Loading
                    open={this.state.loading}
                />
                <MessageSnackbar
                    open={this.state.message}
                    style={this.state.onMessage.style}
                    msg={this.state.onMessage.msg}
                    close={() => this.setState({ message: false })}
                />
                <Grid container>
                    <ItemGrid xs={12} sm={6} md={9}>
                        <RegularCard
                            content={
                                <div>
                                    <Calendar
                                        startDate={this.props.startDate} // momentPropTypes.momentObj or null,
                                        endDate={this.props.endDate}
                                        
                                        showPerDay={false}
                                    />

                                </div>
                            }
                        />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={3}>
                        <RegularCard
                            content={
                                <div>
                                    <Button onClick={() => this.savePdf()} style={{padding: '0 8px', margin: 0, marginRight: '6px'}}
                                        small color="success">Download Report</Button>
                                    <CategoryFilterButton
                                        categoriesHidden = {this.state.categoriesHidden}
                                        categories={this.state.data}
                                        onCategorySelected={categories => {
                                            this.setState({categoriesHidden: categories},()=>{
                                                this.postConfigDashboard();
                                            });
                                            
                                        }}
                                    />
                                    <FormControlLabel control={<Checkbox
                                        checked={this.state.showItemsSold}
                                        onChange={this.handleChange('showItemsSold')}/>} label={'Show only items sold '}/>

                                </div>
                            }
                        />
                    </ItemGrid>
                </Grid>
                <div id="contentPrint">
                    {this.state.data.map((obj) => {
                        let products = obj.products.filter(product=>product.solds!=0);
                        console.log('products length');
                        console.log(products.length);
                        if (categoriesHidden.indexOf(obj.id) < 0 && products.length > 0)
                            return (<RegularCard
                                cardTitle={obj.name}
                                key={obj.id}
                                content={
                                    <div>
                                        <Table
                                            tableHeaderColor="primary"
                                            tablehead={tableHead}
                                            tabledata={obj.products}
                                            order={'desc'}
                                            orderBy={'solds'}
                                            tablerowrender={(product, key) => {
                                                if ((showItemsSold === false&&product.solds === 0) || product.solds > 0)
                                                    return (
                                                        <TableRow key={key}>
                                                            <TableCell>{product.name}</TableCell>
                                                            <TableCell numeric>{product.solds}</TableCell>
                                                            <TableCell numeric>{product.refundeds}</TableCell>
                                                            <TableCell numeric>{product.revenues}</TableCell>
                                                            <TableCell numeric>{product.modifiers_revenue}</TableCell>
                                                            <TableCell numeric>{product.order_discount}</TableCell>
                                                            <TableCell numeric>
                                                                {product.totals!== null&&<CurrencyFormat value={product.totals}
                                                                    displayType={'text'} thousandSeparator={true}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    prefix={'$'}/>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                            }}
                                            tablefooter={
                                                <TableRow >
                                                    <TableCell>Total</TableCell>
                                                    <TableCell numeric>{this.calcSolds(obj.products)}</TableCell>
                                                    <TableCell numeric></TableCell>
                                                    <TableCell numeric></TableCell>
                                                    <TableCell numeric></TableCell>
                                                    <TableCell numeric></TableCell>
                                                    <TableCell numeric>
                                                        {this.calcTotal(obj.products)!== null&&<CurrencyFormat value={this.calcTotal(obj.products)}
                                                            displayType={'text'} thousandSeparator={true}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            prefix={'$'}/>}
                                                        {}</TableCell>

                                                </TableRow>
                                            }
                                        />
                                    </div>
                                }
                            />);
                        else
                            return null;
                    })}
                </div>

            </div>
        );
    }
}

SummarySales.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        startDate: state.calendar.startDate,
        endDate: state.calendar.endDate,
        holiday: state.calendar.holiday,
        optionPerDay: state.calendar.optionPerDay,
        groupBy: state.calendar.groupBy,
        timeSelect: state.calendar.timeSelect,
        live: state.calendar.live
    };
}

export default connect(mapStateToProps)(withStyles(reportsStyle)(SummarySales));