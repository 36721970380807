import LoginView from 'views/User/LoginView.jsx';
import CreateAccount from 'views/User/CreateAccount.jsx';
import LostPassword from 'views/User/LostPassword.jsx';
import SetPassword from 'views/User/SetPassword.jsx';

const loginRoutes = [
    {path: '/user/login', component: LoginView, type: 'login'},
    { path: '/user/register', component: CreateAccount, type: 'register' },
    { path: '/user/lost-password', component: LostPassword,  type: 'lostPassword'},
    { path: '/user/password-reset/:uid/:token', component: SetPassword,  type: 'SetPassword'},
    { redirect: true, path: '/user', to: '/user/login', navbarName: 'Redirect' }
    // { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default loginRoutes;
