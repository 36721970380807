
import React from 'react';
import Plot from 'react-plotly.js';
import { connect } from 'react-redux';


class SalesPerItem extends React.Component{
    render(){
        return (
            <Plot
                data={[{
                    y: this.props.data.map((obj) => obj['count']),
                    x: this.props.data.map((obj) => obj['name']),
                    type: 'bar',
                }]}
                ref="plotlyDialog"
                useResizeHandler={true}
                style={{width:'100%',height:'100%'}}
            />);
    }
}
function mapStateToProps(state) {
    return {
        startDate: state.calendar.startDate,
        endDate: state.calendar.endDate,
        optionPerDay: state.calendar.optionPerDay,
        groupBy: state.calendar.groupBy,
        timeSelect: state.calendar.timeSelect
    };
}

export default connect(mapStateToProps)(SalesPerItem);